import React, {Component, useState, setState, Fragment, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ArrowBackIosTwoToneIcon from '@material-ui/icons/ArrowBackIosTwoTone';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import stockwerk0 from '../../../assets/images/modules/rauchmeldersystem/rauchmelder_stockwerk0.png';
import stockwerk1 from '../../../assets/images/modules/rauchmeldersystem/rauchmelder_stockwerk1.png';
import stockwerk2 from '../../../assets/images/modules/rauchmeldersystem/rauchmelder_stockwerk2.png';

import SmokeDataTableRow from '../rauchmeldersystem/SmokeDataTableRow';
import SkylightHistoryTableRow from './SkylightHistoryTableRow';
import SmokeHistoryTableRow from './SmokeHistoryTableRow';



export default class Rauchmeldersystem extends Component{

    constructor(props) {
        super(props);

        this.gc1loc = "2.0.1 - 2.0.11";
        this.gc2loc = "3.0.1 - 3.0.2";
        this.gc3loc = "1.0.1 - 1.0.10";
        this.gc4loc = "2.1.1 - 2.1.14";
        this.gc5loc = "3.1.1 - 3.1.2";
        this.gc6loc = "1.1.1 - 1.1.7";
        this.gc7loc = "2.2.1 - 2.2.11";
        this.gc8loc = "3.2.1 - 3.2.2";
    
        this.state = {
            abzugH1AufDisabled: true,
            abzugH1ZuDisabled: true,
            abzugH2AufDisabled: true,
            abzugH2ZuDisabled: true,
            statusH1Color: 'black',
            statusH2Color: 'black',
            message: '',
            rwmlist: [],
            smokeAlarmData1: [],
            smokeAlarmData2: [],
            smokeAlarmData3: [],
            smokeAlarmData4: [],
            smokeAlarmData5: [],
            smokeAlarmData6: [],
            smokeAlarmData7: [],
            smokeAlarmData8: [],
            activeAlarmGC1: [],
            activeAlarmGC2: [],
            activeAlarmGC3: [],
            activeAlarmGC4: [],
            activeAlarmGC5: [],
            activeAlarmGC6: [],
            activeAlarmGC7: [],
            activeAlarmGC8: [],
            skylightHistoryJson: [],
            skylightUsername: '',
            smokeHistoryJson: []
        };
    }

    postToSkylightHistory(haus, command) {

        let data = {
            username: this.state.skylightUsername,
            haus: haus,
            command : command,
            location : "ZVC Web" 
        };

        const requestOptionsSkylightHistory = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'}  ,   
            body: JSON.stringify(data)                   
        }

        console.log("Posting to LH: " + JSON.stringify(data))
    
        // FETCH 
        fetch('https://api.zvconnect.de/lichtkuppel/post/', requestOptionsSkylightHistory)
        .then(response => response.json())
        .then(responseJson => console.log("Posted to Lichtkuppel History"));
    }
 
    componentDidMount() {

        var userName = sessionStorage.getItem('username');
        this.setState({skylightUsername: userName});

        const requestOptionsSkylightHistory = {
            method: 'GET',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}                        
        }
    
        // FETCH 
        fetch('https://api.zvconnect.de/lichtkuppel/get/', requestOptionsSkylightHistory)
        .then(response => response.json())
        .then(responseJson => {this.setState({skylightHistoryJson: responseJson});
        }) 

        
        var currentDateSimplified;

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yy = today.getFullYear().toString().substr(-2);            
        currentDateSimplified = dd + "/" + mm + "/" + yy;

        // FETCH 
        fetch('https://api.zvconnect.de/rwm/?enddate=' + currentDateSimplified + '&devid=2', requestOptionsSkylightHistory)
        .then(response => response.json())
        .then(responseJson => {this.setState({smokeHistoryJson: responseJson});
        })

        var lookup = {            
            "842E14FFFEE54B5E": {
                "NAME": "1.0.1/RWM1",
                "GC": 3,
                "ROOM": "1.0.1"
            },
            "842E14FFFEE54CA7": {
                "NAME": "1.0.2/RWM1",
                "GC": 3,
                "ROOM": "1.0.2"
            },
            "842E14FFFEE54BBD": {
                "NAME": "1.0.3/RWM1",
                "GC": 3,
                "ROOM": "1.0.3"
            },
            "842E14FFFEE54650": {
                "NAME": "1.0.4/RWM1",
                "GC": 3,
                "ROOM": "1.0.4"
            },
            "842E14FFFEE54B2C": {
                "NAME": "1.0.5/RWM1",
                "GC": 3,
                "ROOM": "1.0.5"
            },
            "842E14FFFEE54B48": {
                "NAME": "1.0.6/RWM1",
                "GC": 3,
                "ROOM": "1.0.6"
            },
            "842E14FFFEE54D69": {
                "NAME": "1.0.6/RWM2",
                "GC": 3,
                "ROOM": "1.0.6"
            },
            "842E14FFFEED79C9": {
                "NAME": "1.0.7/RWM1",
                "GC": 3,
                "ROOM": "1.0.7"
            },
            "842E14FFFEE54B97": {
                "NAME": "1.0.7/RWM2",
                "GC": 3,
                "ROOM": "1.0.7"
            },
            "842E14FFFEE54D15": {
                "NAME": "1.0.8/RWM1",
                "GC": 3,
                "ROOM": "1.0.8"
            },
            "842E14FFFEED7FC9": {
                "NAME": "1.0.9/RWM1",
                "GC": 3,
                "ROOM": "1.0.9"
            },
            "842E14FFFEE54D31": {
                "NAME": "1.0.10/RWM1",
                "GC": 3,
                "ROOM": "1.0.10"
            },
            "842E14FFFEE54D13": {
                "NAME": "1.1.1/RWM1",
                "GC": 6,
                "ROOM": "1.1.1"
            },
            "842E14FFFEE359E1": {
                "NAME": "1.1.1/RWM2",
                "GC": 6,
                "ROOM": "1.1.1"
            },
            "842E14FFFEEF5941": {
                "NAME": "1.1.2/RWM1",
                "GC": 6,
                "ROOM": "1.1.2"
            },
            "842E14FFFEED79EA": {
                "NAME": "1.1.3/RWM1",
                "GC": 6,
                "ROOM": "1.1.3"
            },
            "842E14FFFEE54CC9": {
                "NAME": "1.1.4/RWM1",
                "GC": 6,
                "ROOM": "1.1.4"
            },
            "842E14FFFEE35957": {
                "NAME": "1.1.5/RWM1",
                "GC": 6,
                "ROOM": "1.1.5"
            },
            "842E14FFFEE54D12": {
                "NAME": "1.1.6/RWM1",
                "GC": 6,
                "ROOM": "1.1.6"
            },
            "842E14FFFEE54CAC": {
                "NAME": "1.1.6/RWM2",
                "GC": 6,
                "ROOM": "1.1.6"
            },
            "842E14FFFEED7908": {
                "NAME": "1.1.7/RWM1",
                "GC": 6,
                "ROOM": "1.1.7"
            },
            "842E14FFFEE54BB8": {
                "NAME": "2.0.1/RWM1",
                "GC": 1,
                "ROOM": "2.0.1"
            },
            "842E14FFFEE54CC6": {
                "NAME": "2.0.2/RWM1",
                "GC": 1,
                "ROOM": "2.0.2"
            },
            "842E14FFFEE54C4D": {
                "NAME": "2.0.3/RWM1",
                "GC": 1,
                "ROOM": "2.0.3"
            },
            "842E14FFFEE54D73": {
                "NAME": "2.0.4/RWM1",
                "GC": 1,
                "ROOM": "2.0.4"
            },
            "842E14FFFEE54CD4": {
                "NAME": "2.0.5/RWM1",
                "GC": 1,
                "ROOM": "2.0.5"
            },
            "842E14FFFEE54D7A": {
                "NAME": "2.0.6/RWM1",
                "GC": 1,
                "ROOM": "2.0.6"
            },
            "842E14FFFEE359FD": {
                "NAME": "2.0.7/RWM1",
                "GC": 1,
                "ROOM": "2.0.7"
            },
            "842E14FFFEE54D16": {
                "NAME": "2.0.8/RWM1",
                "GC": 1,
                "ROOM": "2.0.8"
            },
            "842E14FFFEED790B": {
                "NAME": "2.0.9/RWM1",
                "GC": 1,
                "ROOM": "2.0.9"
            },
            "842E14FFFEE54B82": {
                "NAME": "2.0.10/RWM1",
                "GC": 1,
                "ROOM": "2.0.10"
            },
            "842E14FFFEED79CA": {
                "NAME": "2.0.11/RWM1",
                "GC": 1,
                "ROOM": "2.0.11"
            },
            "842E14FFFEE54BC0": {
                "NAME": "2.1.1/RWM1",
                "GC": 4,
                "ROOM": "2.1.1"
            },
            "842E14FFFEED79DB": {
                "NAME": "2.1.2/RWM1",
                "GC": 4,
                "ROOM": "2.1.2"
            },
            "842E14FFFEE54B75": {
                "NAME": "2.1.3/RWM1",
                "GC": 4,
                "ROOM": "2.1.3"
            },
            "842E14FFFEE548FB": {
                "NAME": "2.1.4/RWM1",
                "GC": 4,
                "ROOM": "2.1.4"
            },
            "842E14FFFEE5482C": {
                "NAME": "2.1.5/RWM1",
                "GC": 4,
                "ROOM": "2.1.5"
            },
            "842E14FFFEE54B9E": {
                "NAME": "2.1.6/RWM1",
                "GC": 4,
                "ROOM": "2.1.6"
            },
            "842E14FFFEE5497F": {
                "NAME": "2.1.7/RWM1",
                "GC": 4,
                "ROOM": "2.1.7"
            },
            "842E14FFFEE54BCF": {
                "NAME": "2.1.8/RWM1",
                "GC": 4,
                "ROOM": "2.1.8"
            },
            "842E14FFFEE54D70": {
                "NAME": "2.1.9/RWM1",
                "GC": 4,
                "ROOM": "2.1.9"
            },
            "842E14FFFEED7F52": {
                "NAME": "2.1.10/RWM1",
                "GC": 4,
                "ROOM": "2.1.10"
            },
            "842E14FFFEE548F4": {
                "NAME": "2.1.11/RWM1",
                "GC": 4,
                "ROOM": "2.1.11"
            },
            "842E14FFFEE54BB3": {
                "NAME": "2.1.12/RWM1",
                "GC": 4,
                "ROOM": "2.1.12"
            },
            "842E14FFFEE54983": {
                "NAME": "2.1.13/RWM1",
                "GC": 4,
                "ROOM": "2.1.13"
            },
            "842E14FFFEED792A": {
                "NAME": "2.1.14/RWM1",
                "GC": 4,
                "ROOM": "2.1.14"
            },
            "842E14FFFEE359E9": {
                "NAME": "2.2.1/RWM1",
                "GC": 7,
                "ROOM": "2.2.1"
            },
            "842E14FFFEE359F8": {
                "NAME": "2.2.2/RWM1",
                "GC": 7,
                "ROOM": "2.2.2"
            },
            "842E14FFFEED78C5": {
                "NAME": "2.2.3/RWM1",
                "GC": 7,
                "ROOM": "2.2.3"
            },
            "842E14FFFEE54CAF": {
                "NAME": "2.2.3/RWM2",
                "GC": 7,
                "ROOM": "2.2.3"
            },
            "842E14FFFEE54C51": {
                "NAME": "2.2.4/RWM1",
                "GC": 7,
                "ROOM": "2.2.4"
            },
            "842E14FFFEE54D02": {
                "NAME": "2.2.5/RWM1",
                "GC": 7,
                "ROOM": "2.2.5"
            },
            "842E14FFFEE54C39": {
                "NAME": "2.2.6/RWM1",
                "GC": 7,
                "ROOM": "2.2.6"
            },
            "842E14FFFEE359D3": {
                "NAME": "2.2.7/RWM1",
                "GC": 7,
                "ROOM": "2.2.7"
            },
            "842E14FFFEE54AD5": {
                "NAME": "2.2.8/RWM1",
                "GC": 7,
                "ROOM": "2.2.8"
            },
            "842E14FFFEE54D78": {
                "NAME": "2.2.9/RWM1",
                "GC": 7,
                "ROOM": "2.2.9"
            },
            "842E14FFFEED7B00": {
                "NAME": "2.2.10/RWM1",
                "GC": 7,
                "ROOM": "2.2.10"
            },
            "842E14FFFEE54BBF": {
                "NAME": "2.2.11/RWM1",
                "GC": 7,
                "ROOM": "2.2.11"
            },
            "842E14FFFEE54CC1": {
                "NAME": "3.0.1/RWM1",
                "GC": 2,
                "ROOM": "3.0.1"
            },
            "842E14FFFEE54BA4": {
                "NAME": "3.0.2/RWM1",
                "GC": 2,
                "ROOM": "3.0.2"
            },
            "842E14FFFEE54953": {
                "NAME": "3.1.1/RWM1",
                "GC": 5,
                "ROOM": "3.1.1"
            },
            "842E14FFFEED7A89": {
                "NAME": "3.1.2/RWM1",
                "GC": 5,
                "ROOM": "3.1.2"
            },
            "842E14FFFEE359F6": {
                "NAME": "3.2.1/RWM1",
                "GC": 8,
                "ROOM": "3.2.1"
            },
            "842E14FFFEE54D67": {
                "NAME": "3.2.2/RWM1",
                "GC": 8,
                "ROOM": "3.2.2"
            },
            "842E14FFFEED7930": {
                "NAME": "3.2.2/RWM2",
                "GC": 8,
                "ROOM": "3.2.2"
            },
            "842E14FFFEE5481D": {
                "NAME": "2.0.3/RWM2",
                "GC": 1,
                "ROOM": "2.0.3"
            },
            "842E14FFFEED79F1": {
                "NAME": "2.0.1/RWM2",
                "GC": 1,
                "ROOM": "2.0.1"
            },
            "842E14FFFEE54D14": {
                "NAME": "2.0.2/RWM2",
                "GC": 1,
                "ROOM": "2.0.2"
            },
            "842E14FFFEE54D08": {
                "NAME": "2.0.4/RWM2",
                "GC": 1,
                "ROOM": "2.0.4"
            },
            "842E14FFFEE54D0F": {
                "NAME": "1.0.8/RWM2",
                "GC": 3,
                "ROOM": "1.0.8"
            }   
        }

        const RMVcount = 8;
        const mqtt = require('mqtt')
        const Broker = "mqtts://mqtt.consoft.de:8001";
        const options = {
            protocol: 'mqtts',
            clientId: 'EZGRWMTEST' + Math.floor(Math.random() * 1000)
        };
        const Topic1 = "Dehoust/RWM/EZG/IN/GCJSNC";
        
        const client = mqtt.connect(Broker, options);
            
        client.on('connect', function () {        
        
            console.log('Rauchmelder CONNECTED as ' + options.clientId);   
        
            client.subscribe(Topic1, function (err) {;
                if (!err) {
                console.log('SUBBED TOPIC 1');
                checkSynctimes();                          
                }
            })
            client.subscribe("Dehoust/RWM/EZG/IN/RA1state");
            client.subscribe("Dehoust/RWM/EZG/IN/RA2state");
        })

        client.on('message', function (topic, message) {
            // message is Buffer  
            console.log(topic.toString() + ' ' + message.toString());
            //var kektest = {GC:'3', batVoltage:'7V', batCharge:'No', batErr:'No', ErrC:'1'} GCJSNC

            if (topic.toString().endsWith('RA1state') || topic.toString().endsWith('RA2state')) {
                //0 = schießend 2 = öffnend 5 = undefiniert nach neustart 10 = geschlossen 12 = offen
                
                var abzugID = 0;
                var abzugStatusLabelID = ''
                var abzugAufStateID = '';
                var abzugZuStateID = '';
                var statusColor = '';
                if (topic.toString().endsWith('RA1state')) {
                    abzugID = 1;
                    abzugStatusLabelID = 'abzugH1status';
                    abzugAufStateID = 'abzugH1AufDisabled';
                    abzugZuStateID = 'abzugH1ZuDisabled';
                    statusColor = 'statusH1Color';
                }
                else  if (topic.toString().endsWith('RA2state')) {
                    abzugID = 2;
                    abzugStatusLabelID = 'abzugH2status';  
                    abzugAufStateID = 'abzugH2AufDisabled';
                    abzugZuStateID = 'abzugH2ZuDisabled';
                    statusColor = 'statusH2Color';    
                }

                if (abzugID > 0) {                    
                    var status = message.toString();
                                        
                    if (document.getElementById(abzugStatusLabelID) !== null && document.getElementById(abzugStatusLabelID) !== undefined) {
                        // STATES ARE REFERENCING DISABLED STATUS // TRUE = DISABLED
                        // 0 = schließend
                        if (status === '0') {
                            document.getElementById(abzugStatusLabelID).innerHTML = 'schließend';
                            this.setState({[abzugAufStateID]: true,});
                            this.setState({[abzugZuStateID]: true,});
                            this.setState({[statusColor]: '#DB423A',});
                        } 
                        // 2 = öffnend
                        if (status === '2') {
                            document.getElementById(abzugStatusLabelID).innerHTML = 'öffnend';
                            this.setState({[abzugAufStateID]: true,});
                            this.setState({[abzugZuStateID]: true,});
                            this.setState({[statusColor]: '#2eb41c',});                        
                        }               
                        // 5 = undefiniert nach Neustart
                        if (status === '5') {
                            document.getElementById(abzugStatusLabelID).innerHTML = 'undefiniert';
                            this.setState({[abzugAufStateID]: false,});
                            this.setState({[abzugZuStateID]: false,}); 
                        }
                        // 10 = geschlossen
                        if (status === '10') {
                            document.getElementById(abzugStatusLabelID).innerHTML = 'geschlossen';
                            this.setState({[abzugAufStateID]: false,});
                            this.setState({[abzugZuStateID]: true,});
                            this.setState({[statusColor]: '#DB423A',}); 
                        }
                        // 12 = offen
                        if (status === '12') {
                            document.getElementById(abzugStatusLabelID).innerHTML = 'offen';
                            this.setState({[abzugAufStateID]: true,});
                            this.setState({[abzugZuStateID]: false,});
                            this.setState({[statusColor]: '#2eb41c',}); 
                        }   
                    } 
                }
            }

            if (topic.toString().endsWith('GCJSNC')) {

                var voltOfflineIndicator = document.getElementById("smokeOffline");
                var voltOnlineIndicator = document.getElementById("smokeOnline");            
            
                if (voltOnlineIndicator != null && voltOnlineIndicator.classList.contains('hide')) {
                    voltOfflineIndicator.classList.add('hide');   
                    voltOnlineIndicator.classList.remove('hide'); 
                } 

                var messagePreprocessor = JSON.parse(message)
                messagePreprocessor.batVoltage = ((messagePreprocessor.batVoltage/10) + 'V');
                messagePreprocessor = {...messagePreprocessor, timer: 0};
                var currentGC = 'smokeAlarmData' + messagePreprocessor.GC;
                var registerAlarmForGC = 'activeAlarmGC' + messagePreprocessor.GC; // activeAlarmGC4
    
                // ERROR CODE 
                var errCode = messagePreprocessor.ErrC.toString();
                var status = '';
                if (errCode === '0') {
                    status = 'OK';
                    // CHECK IF GC HAS ACTIVE ALARM STORED AS CURRENT STATUS
                    if (this.state[registerAlarmForGC] === 1) { // select state with dynamic key
                        console.log('weird select succeeded');
                        this.setState({
                            [registerAlarmForGC]: 0,
                        });
                        // SET ALL ROOMS FOR THIS GC TO GREEN
                        var activeGCStoSetGreen = document.getElementsByClassName('GC' +  messagePreprocessor.GC);
                        console.log("activeGCStoSetGreen: " + activeGCStoSetGreen);
                        Array.from(activeGCStoSetGreen).forEach((el) => {
                            console.log("el: " + el);
                            el.classList.remove('planRWMAlarm');
                    });
                    }
                }
                if (errCode === '1') {
                    var rwmID = messagePreprocessor.RWM;
                    var rwmName = lookup[rwmID]["NAME"];                
                    status = 'keine Verbindung zu: ' + rwmName;
                }
                if (errCode === '2') {
                    var rwmID = messagePreprocessor.RWM;
                    var rwmName = lookup[rwmID]["NAME"];
                    //RWM Exists in Lookup Table
                    if (rwmName !== null && rwmName !== undefined){
                        status = 'Alarm: ' + rwmName;
                        // Set RWM Icon to RED
                        var nameToId = rwmName;
                        var nameNoDots = nameToId.replace('.','');
                        var nameNoDotsTwo = nameNoDots.replace('.','');
                        var nameNoSlash = nameNoDotsTwo.replace('/','');
                        console.log(nameNoSlash);                    
                        var rwmIcon = document.getElementById("RM"+nameNoSlash);
                        // IF RWM has corresponding Icon in Lageplan
                        if (rwmIcon !== undefined && rwmIcon !== null)
                        {
                            rwmIcon.classList.add('planRWMAlarm');
                            //                        
                            this.setState({
                                [registerAlarmForGC]: 1,
                            });  
                        }                   
                    }
                    // RWM NOT in Lookup Table -> Just show Alarm
                    else {
                        status = 'Alarm! Unbekannter RWM' 
                    }
                }
                messagePreprocessor = {...messagePreprocessor, status: status}
                
                // Stats Fillup
                var stats = parseInt(messagePreprocessor.Stats,10).toString(2);
                while (stats.length<16) stats="0"+stats;
                // MainV Addition
                var MainV=stats[15-0]=="1"?"Yes":"No";
                messagePreprocessor = {...messagePreprocessor, mainVoltage: MainV};
    
                // Connection NWK Builder
                var hasConn="";
                var hasNoConn="";            
                for (var i=0;i<RMVcount;i++)
                {
                    if (stats[15-(i+1)]=="1") 
                    {
                        if (hasConn!="") hasConn+=",";
                        hasConn+=(i+1);
                    } else
                    {
                        if (hasNoConn!="") hasNoConn+=",";
                        hasNoConn+=(i+1);
                    }
                }            
                var ConRes=hasConn;
                if (hasNoConn!="") ConRes+="["+hasNoConn+"]";
                messagePreprocessor.Stats = ConRes;
    
                // Reset Last Sync for GC
                var sf = document.getElementById("syncfield" + messagePreprocessor.GC);
                if (sf !== null) {
                    sf.innerHTML = 0;
                }
    
                // Set State
                this.setState({
                     [currentGC]: [messagePreprocessor],
                });    
            }            
        }.bind(this))
        
        client.on('close', function () {            
            console.log("Raumtemperatur DISCONNECTED")        
        })

        function checkSynctimes() {
            var els = document.getElementsByClassName("syncTimes");
            Array.from(els).forEach((el) => {
                var oldTime = parseInt(el.innerHTML, 10)
                el.innerHTML = oldTime + 1;                 
            });
           
        }setInterval(checkSynctimes, 1000);

        // MUTE Switch
        const muteButton = document.getElementById('smokeMute');
        muteButton.addEventListener('click', (event) => {
            
            document.getElementById('smokeMuteSentTxt').innerHTML = "Mute 10min verschickt!";
            setTimeout(function(){
                document.getElementById("smokeMuteSentTxt").innerHTML = '';
            }, 3000);
            
            client.publish("Dehoust/RWM/EZG/OUT", "[MUTEant]");
        });

        // NOMUTE Switch
        // const noMuteButton = document.getElementById('smokeNoMute');
        // noMuteButton.addEventListener('click', (event) => {
        //     client.publish("Dehoust/RWM/EZG/OUT", "[MUTE:0]");
        // });
         // NOALARM Switch
        //  const noAlarmButton = document.getElementById('smokeNoAlarm');
        //  noAlarmButton.addEventListener('click', (event) => {
        //      client.publish("Dehoust/RWM/EZG/OUT", "[ALARMOFF]");
        //  });

         // ABZUG HAUS 1
         const abzugH1aufButton = document.getElementById('abzugH1auf');
         abzugH1aufButton.addEventListener('click', (event) => {
            client.publish("Dehoust/RWM/EZG/RA1", "2");
            this.postToSkylightHistory("1","opened");

            // Set to Yellow/Orange
            this.setState({['statusH1Color']: '#e96210',}); 
            document.getElementById("abzugH1status").innerHTML = 'wartend';
        });

        const abzugH1zuButton = document.getElementById('abzugH1zu');
        abzugH1zuButton.addEventListener('click', (event) => {
            client.publish("Dehoust/RWM/EZG/RA1", "0");
            this.postToSkylightHistory("1","closed");

            // Set to Yellow/Orange
            this.setState({['statusH1Color']: '#e96210',}); 
            document.getElementById("abzugH1status").innerHTML = 'wartend';  
        });

        // ABZUG HAUS 2
        const abzugH2aufButton = document.getElementById('abzugH2auf');
        abzugH2aufButton.addEventListener('click', (event) => {
            client.publish("Dehoust/RWM/EZG/RA2", "2");
            this.postToSkylightHistory("2","opened");

            // Set to Yellow/Orange
            this.setState({['statusH2Color']: '#e96210',}); 
            document.getElementById("abzugH2status").innerHTML = 'wartend';   
        });

        const abzugH2zuButton = document.getElementById('abzugH2zu');
        abzugH2zuButton.addEventListener('click', (event) => {
            client.publish("Dehoust/RWM/EZG/RA2", "0");
            this.postToSkylightHistory("2","closed");

            // Set to Yellow/Orange
            this.setState({['statusH2Color']: '#e96210',}); 
            document.getElementById("abzugH2status").innerHTML = 'wartend';  
       });
         
    };

    render() {   
    return (
        <div className="module-wrapper">
            <div className="distancer">
                {/* // SUBNAVIGATION */}
                <div className="subnav">
                    <div className="fltl">
                        <Link to="/Devices">
                            <Button variant="contained" color="primary" className="back-button" startIcon={<ArrowBackIosTwoToneIcon/>}>Geräte</Button>
                        </Link>
                    </div>
                    <div className="fltr">
                        <Button id="smokeOnline" variant="outlined" color="primary" className="online fltr hide" disableElevation disabled startIcon={<LinkIcon/>}>Verbunden</Button>
                        <Button id="smokeOffline" variant="outlined" color="primary" className="offline fltr" disableElevation disabled startIcon={<LinkOffIcon/>}>Verbindung</Button>
                    </div>
                    <div className="spacer"></div>                     
                </div>
                {/* END SUBNAVIGATION*/}  
                <div className="outer-title distancer-bottom">
                    Rauchmeldersystem
                </div>
            </div>
            <div>            
                <Card>
                    <Card.Header>
                        <div className="card-title nopad">Rauchabzüge</div>
                    </Card.Header>
                    <Card.Body>
                        <div className="groupLightswitch">
                            <div className="cardLabel">
                                <span className="">Rauchabzug: <b>Haus 1</b></span><br>
                                </br>
                                <span>Status: </span><span id="abzugH1status" style={{fontWeight: "bold", color: this.state.statusH1Color}}></span>
                            </div>
                            <div className="cardGroupSwitch">
                                <div className="card-title-buttons">
                                    <Button id="abzugH1auf" variant="contained" color="" className="generic-btn fltr" disabled={this.state.abzugH1AufDisabled}>Öffnen</Button>
                                    <Button id="abzugH1zu" variant="contained" color="" className="generic-btn fltr" disabled={this.state.abzugH1ZuDisabled}>Schließen</Button>
                                </div>                         
                            </div>
                        </div>
                        <hr></hr>
                        <div className="groupLightswitch">
                            <div className="cardLabel">
                                <span className="">Rauchabzug: <b>Haus 2</b></span><br>
                                </br>
                                <span>Status: </span><span id="abzugH2status" style={{fontWeight: "bold", color: this.state.statusH2Color}}></span>
                            </div>
                            <div className="cardGroupSwitch">
                                <div className="card-title-buttons">
                                <Button id="abzugH2auf" variant="contained" color="" className="generic-btn fltr" disabled={this.state.abzugH2AufDisabled}>Öffnen</Button>
                                <Button id="abzugH2zu" variant="contained" color="" className="generic-btn fltr" disabled={this.state.abzugH2ZuDisabled}>Schließen</Button>
                                </div>                         
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <br></br>
                <Card>
                    <Card.Header>
                        <div className="card-title nopad no576">Group-Center</div>
                        <div className="card-title-buttons">
                            <span id="smokeMuteSentTxt" style={{fontSize: 15, color: "#2eb41c", fontWeight: "bold"}}></span>
                            <Button id="smokeMute" variant="contained" color="" className="generic-btn fltr">Mute (10min)</Button>                            
                            {/* <Button id="smokeNoMute" variant="contained" color="" className="generic-btn fltr">Mute aus</Button>
                            <Button id="smokeNoAlarm" variant="contained" color="" className="generic-btn fltr">Alarm aus</Button> */}
                        </div>
                    </Card.Header>
                    <Card.Body style={{minHeight: 336}}>                        
                        <Table striped bordered hover size="sm" aria-sort="descending">
                            <thead>
                                <tr>
                                <th>GC</th>
                                <th>Raum</th>
                                <th>Netz</th>
                                <th>Bat V</th>
                                <th>Bat Lädt</th>
                                <th>Bat Err</th>
                                <th className="hideXS">Netzwerk</th>
                                <th>Last Sync</th>
                                <th>Status</th>                                                            
                                </tr>
                            </thead>
                            <tbody id="smokeTableBody">                                
                                {this.state.smokeAlarmData1.map(dataSet =>  (
                                    <SmokeDataTableRow GC={dataSet.GC} ort={this.gc1loc} mainVoltage={dataSet.mainVoltage} batVoltage={dataSet.batVoltage} batCharge={dataSet.batCharge} batErr={dataSet.batErr} Stats={dataSet.Stats} timer={dataSet.timer} status={dataSet.status}/>
                                ))}
                                {this.state.smokeAlarmData2.map(dataSet =>  (
                                    <SmokeDataTableRow GC={dataSet.GC} ort={this.gc2loc} mainVoltage={dataSet.mainVoltage} batVoltage={dataSet.batVoltage} batCharge={dataSet.batCharge} batErr={dataSet.batErr} Stats={dataSet.Stats} timer={dataSet.timer} status={dataSet.status}/>
                                ))}
                                    {this.state.smokeAlarmData3.map(dataSet =>  (
                                    <SmokeDataTableRow GC={dataSet.GC} ort={this.gc3loc} mainVoltage={dataSet.mainVoltage} batVoltage={dataSet.batVoltage} batCharge={dataSet.batCharge} batErr={dataSet.batErr} Stats={dataSet.Stats} timer={dataSet.timer} status={dataSet.status}/>
                                ))}
                                    {this.state.smokeAlarmData4.map(dataSet =>  (
                                    <SmokeDataTableRow GC={dataSet.GC} ort={this.gc4loc} mainVoltage={dataSet.mainVoltage} batVoltage={dataSet.batVoltage} batCharge={dataSet.batCharge} batErr={dataSet.batErr} Stats={dataSet.Stats} timer={dataSet.timer} status={dataSet.status}/>
                                ))}   
                                    {this.state.smokeAlarmData5.map(dataSet =>  (
                                    <SmokeDataTableRow GC={dataSet.GC} ort={this.gc5loc} mainVoltage={dataSet.mainVoltage} batVoltage={dataSet.batVoltage} batCharge={dataSet.batCharge} batErr={dataSet.batErr} Stats={dataSet.Stats} timer={dataSet.timer} status={dataSet.status}/>
                                ))}
                                    {this.state.smokeAlarmData6.map(dataSet =>  (
                                    <SmokeDataTableRow GC={dataSet.GC} ort={this.gc6loc} mainVoltage={dataSet.mainVoltage} batVoltage={dataSet.batVoltage} batCharge={dataSet.batCharge} batErr={dataSet.batErr} Stats={dataSet.Stats} timer={dataSet.timer} status={dataSet.status}/>
                                ))}
                                    {this.state.smokeAlarmData7.map(dataSet =>  (
                                    <SmokeDataTableRow GC={dataSet.GC} ort={this.gc7loc} mainVoltage={dataSet.mainVoltage} batVoltage={dataSet.batVoltage} batCharge={dataSet.batCharge} batErr={dataSet.batErr} Stats={dataSet.Stats} timer={dataSet.timer} status={dataSet.status}/>
                                ))}
                                    {this.state.smokeAlarmData8.map(dataSet =>  (
                                    <SmokeDataTableRow GC={dataSet.GC} ort={this.gc8loc} mainVoltage={dataSet.mainVoltage} batVoltage={dataSet.batVoltage} batCharge={dataSet.batCharge} batErr={dataSet.batErr} Stats={dataSet.Stats} timer={dataSet.timer} status={dataSet.status}/>
                                ))}                                                                                         
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
                <br></br>                
                <Card className="lageplan-card">
                    <Card.Body >
                        <Tabs id="uncontrolled-tab-example" defaultActiveKey="eventEG">
                            <Tab eventKey="eventEG" title="EG">
                                <div className="lageplan-tab">
                                    {/* Stock 0 Haus 1 */}
                                    <div id="RM101RWM1" className="planRWM GC3"/>
                                    <div id="RM102RWM1" className="planRWM GC3"/>
                                    <div id="RM103RWM1" className="planRWM GC3"/> 
                                    <div id="RM104RWM1" className="planRWM GC3"/> 
                                    <div id="RM105RWM1" className="planRWM GC3"/> 
                                    <div id="RM106RWM1" className="planRWM GC3"/>
                                    <div id="RM106RWM2" className="planRWM GC3"/>
                                    <div id="RM107RWM1" className="planRWM GC3"/>
                                    <div id="RM108RWM1" className="planRWM GC3"/>
                                    <div id="RM108RWM2" className="planRWM GC3"/>
                                    <div id="RM109RWM1" className="planRWM GC3"/>
                                    <div id="RM1010RWM1" className="planRWM GC3"/>
                                    {/* Stock 0 Haus 2 */}
                                    <div id="RM201RWM1" className="planRWM GC1"/>
                                    <div id="RM201RWM2" className="planRWM GC1"/>
                                    <div id="RM202RWM1" className="planRWM GC1"/>
                                    <div id="RM202RWM2" className="planRWM GC1"/>
                                    <div id="RM203RWM1" className="planRWM GC1"/>
                                    <div id="RM203RWM2" className="planRWM GC1"/> 
                                    <div id="RM204RWM1" className="planRWM GC1"/>
                                    <div id="RM204RWM2" className="planRWM GC1"/> 
                                    <div id="RM205RWM1" className="planRWM GC1"/> 
                                    <div id="RM206RWM1" className="planRWM GC1"/>
                                    <div id="RM207RWM1" className="planRWM GC1"/>
                                    <div id="RM208RWM1" className="planRWM GC1"/>
                                    <div id="RM208RWM2" className="planRWM GC1"/>
                                    <div id="RM209RWM1" className="planRWM GC1"/>
                                    <div id="RM2010RWM1" className="planRWM GC1"/>
                                    <div id="RM2011RWM1" className="planRWM GC1"/>
                                     {/* Stock 0 Haus 3 */}   
                                    <div id="RM301RWM1" className="planRWM GC2"/>
                                    <div id="RM302RWM1" className="planRWM GC2"/>                                                                               
                                    <img id="planStockwerk0" className="rauchmelderPlan"  src={stockwerk0}/>
                                </div>  
                            </Tab>
                            <Tab eventKey="eventEins" title="1. OG">
                                <div className="lageplan-tab">
                                    {/* Stock 1 Haus 1 */}
                                    <div id="RM111RWM1" className="planRWM GC6"/>
                                    <div id="RM111RWM2" className="planRWM GC6"/>
                                    <div id="RM112RWM1" className="planRWM GC6"/>
                                    <div id="RM113RWM1" className="planRWM GC6"/>
                                    <div id="RM114RWM1" className="planRWM GC6"/>
                                    <div id="RM115RWM1" className="planRWM GC6"/>
                                    <div id="RM116RWM1" className="planRWM GC6"/>
                                    <div id="RM116RWM2" className="planRWM GC6"/>
                                    <div id="RM117RWM1" className="planRWM GC6"/>
                                    <div id="RM117RWM2" className="planRWM GC6"/>
                                    {/* Stock 1 Haus 2 */}
                                    <div id="RM211RWM1" className="planRWM GC4"/>
                                    <div id="RM212RWM1" className="planRWM GC4"/>
                                    <div id="RM213RWM1" className="planRWM GC4"/>
                                    <div id="RM214RWM1" className="planRWM GC4"/>
                                    <div id="RM214RWM2" className="planRWM GC4"/>
                                    <div id="RM215RWM1" className="planRWM GC4"/>
                                    <div id="RM216RWM1" className="planRWM GC4"/>
                                    <div id="RM217RWM1" className="planRWM GC4"/>
                                    <div id="RM218RWM1" className="planRWM GC4"/>
                                    <div id="RM219RWM1" className="planRWM GC4"/>
                                    <div id="RM2110RWM1" className="planRWM GC4"/>
                                    <div id="RM2112RWM1" className="planRWM GC4"/> 
                                    <div id="RM2113RWM1" className="planRWM GC4"/> 
                                    <div id="RM2114RWM1" className="planRWM GC4"/> 
                                    {/* Stock 1 Haus 3 */}   
                                    <div id="RM311RWM1" className="planRWM GC5"/>
                                    <div id="RM312RWM1" className="planRWM GC5"/>  
                                    <img id="planStockwerk1" className="rauchmelderPlan"  src={stockwerk1}/>
                                </div>                                
                            </Tab>
                            <Tab eventKey="eventZwei" title="2. OG" >
                                <div className="lageplan-tab">
                                    {/* Stock 2 Haus 2 */}
                                    <div id="RM221RWM1" className="planRWM GC7"/>
                                    <div id="RM222RWM1" className="planRWM GC7"/>
                                    <div id="RM223RWM1" className="planRWM GC7"/>
                                    <div id="RM224RWM1" className="planRWM GC7"/>
                                    <div id="RM225RWM1" className="planRWM GC7"/>
                                    <div id="RM226RWM1" className="planRWM GC7"/>                                    
                                    <div id="RM227RWM1" className="planRWM GC7"/>
                                    <div id="RM228RWM1" className="planRWM GC7"/>
                                    <div id="RM229RWM1" className="planRWM GC7"/>
                                    <div id="RM2210RWM1" className="planRWM GC7"/>
                                    <div id="RM2211RWM1" className="planRWM GC7"/>                                   
                                    {/* Stock 2 Haus 3 */}   
                                    <div id="RM321RWM1" className="planRWM GC8"/>
                                    <div id="RM322RWM1" className="planRWM GC8"/>                                      
                                    <img id="planStockwerk2" className="rauchmelderPlan"  src={stockwerk2}/>
                                </div>     
                            </Tab>
                        </Tabs>                      
                    </Card.Body>
                </Card>
                <br></br>    
                <Card className="lageplan-card">
                    <Card.Body>
                        <Tabs id="uncontrolled-tab-example" defaultActiveKey="skylight">
                            <Tab eventKey="skylight" title="Lichtkuppel">
                                <div className="lageplan-tab">
                                <div style={{fontSize: 12, marginBottom: 4}}>Letzten 10 Ereignisse</div>
                                    <Table striped bordered hover size="sm" aria-sort="descending">
                                        <thead>
                                            <tr>
                                                <th>Status</th>
                                                <th>Haus</th>
                                                <th>Benutzer</th>
                                                <th>Quelle</th> 
                                                <th>Datum</th>                                                                                                        
                                            </tr>
                                        </thead>
                                        <tbody id="smokeTableBody">   
                                            {this.state.skylightHistoryJson.map(dataSet =>  (
                                            <SkylightHistoryTableRow command={dataSet.command} location={dataSet.location} haus={dataSet.haus} user={dataSet.user} created_at={dataSet.created_at}/>
                                            ))}
                                        </tbody> 
                                    </Table>
                                </div>
                            </Tab>
                            <Tab eventKey="smokealarms" title="Rauchmelder">
                                <div className="lageplan-tab" style={{height: 416}}>
                                <div style={{fontSize: 12, marginBottom: 4}}>&nbsp;</div>                             
                                    <Table striped bordered hover size="sm" aria-sort="descending">
                                        <thead>
                                            <tr>
                                                <th>Status</th>
                                                <th>Datum</th>                                                       
                                            </tr>
                                        </thead>
                                        <tbody id="smokeTableBody">   
                                            {this.state.smokeHistoryJson.map(dataSet =>  (
                                            <SmokeHistoryTableRow time={dataSet.time} message={dataSet.txt}/>
                                            ))}
                                        </tbody> 
                                    </Table>
                                </div>
                            </Tab>
                        </Tabs>
                    </Card.Body>
                </Card>
            </div>
        </div> 
        );
    }
}
