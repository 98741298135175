import React, {Component, useState, Fragment, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ArrowBackIosTwoToneIcon from '@material-ui/icons/ArrowBackIosTwoTone';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import signalBar1 from '../../../assets/images/modules/bars_1.png';
import signalBar2 from '../../../assets/images/modules/bars_2.png';
import signalBar3 from '../../../assets/images/modules/bars_3.png';



function Raumtemperatur() {

    //useEffect executes code upon mounting of the component    
    useEffect(()=>{
        // do stuff here...
        var mqtt = require('mqtt')
        var Broker = "mqtts://mqtt.consoft.de:8001";
        var options = {
            protocol: 'mqtts',
            clientId: '1_Test.EControl.Temp.EZGTempWeb_' + Math.floor(Math.random() * 1000),
            username: "testEControlTempClient",
            password: "SchneckenTempo22",
        };
        var MasterTopic = "Test/EControl/TempEZG";

       var client  = mqtt.connect(Broker, options);

        client.on('connect', function () {   

            var voltOfflineIndicator = document.getElementById("roomTempOffline");
            var voltOnlineIndicator = document.getElementById("roomTempOnline");            

            if (voltOnlineIndicator !=  null) {
                voltOfflineIndicator.classList.add('hide');   
                voltOnlineIndicator.classList.remove('hide'); 
            }   

            console.log('Raumtemperatur CONNECTED as ' + options.clientId);            
            client.subscribe(MasterTopic, function (err) {;
                if (!err) {
                console.log('SUBBED');
                client.publish('Test/EControl/TempEZG/command','LATEST');                
                }
            })
        })

        client.on('message', function (topic, message) {
        // message is Buffer        
        var indata = JSON.parse(message);        
        //console.log(indata.data);

            //Haus 1
            if (indata.device === "0000015") {
                document.getElementById("101sensorID").innerText = "15";
                insertRoomData(indata, "101");
            }    
            if (indata.device === "000007") {
                document.getElementById("102sensorID").innerText = "07";
                insertRoomData(indata, "102");
            }    
            if (indata.device === "0000011") {
                document.getElementById("105sensorID").innerText = "11";
                insertRoomData(indata, "105");
            } 
            if (indata.device === "0000012") {
                document.getElementById("106sensorID").innerText = "12";
                insertRoomData(indata, "106");
            }
            if (indata.device === "0000016") {
                document.getElementById("109sensorID").innerText = "16";
                insertRoomData(indata, "109");
            }
            if (indata.device === "0000014") {
                document.getElementById("1010sensorID").innerText = "14";
                insertRoomData(indata, "1010");
            }            
            //Haus 2
            if (indata.device === "000001") {
                document.getElementById("211sensorID").innerText = "01";
                insertRoomData(indata, "211");
            }    
            if (indata.device === "000002") {
                document.getElementById("207sensorID").innerText = "02";
                insertRoomData(indata, "207");
            }    
            if (indata.device === "000004") {
                document.getElementById("2110sensorID").innerText = "04";
                insertRoomData(indata, "2110");
            } 
            if (indata.device === "000005") {
                document.getElementById("2112sensorID").innerText = "05";
                insertRoomData(indata, "2112");
            }
            if (indata.device === "000008") {
                document.getElementById("226sensorID").innerText = "08";
                insertRoomData(indata, "226");
            }              
            //HAUS 3
            if (indata.device === "000006") {
                document.getElementById("301sensorID").innerText = "06";
                insertRoomData(indata, "301");
            }       
            if (indata.device === "0000010") {
            document.getElementById("312sensorID").innerText = "10";
            insertRoomData(indata, "312");
            }
            if (indata.device === "000009") {
                document.getElementById("322sensorID").innerText = "09";
                insertRoomData(indata, "322");
            }            
        })

        function insertRoomData(indata, roomID){            
            // TEMPERATURE
            var temp = document.getElementById(roomID + "roomTemp");
            var newTemp;
            if (indata.data.temp % 1 === 0) {
                newTemp = indata.data.temp + ",0 °C";
            }
            else {
                newTemp = indata.data.temp.toString().replace(".", ",") + " °C";		
            }
            temp.innerHTML = newTemp;

            // BATTERY LEVEL
            var mvolts = document.getElementById(roomID + "roomBattery");
            var mvoltsInt = parseInt(indata.data.mvolts, 10); 
            var batteryLevel;

            if (mvoltsInt < 500) {
                batteryLevel = 0;
            }
            else if (mvoltsInt > 700) {
                batteryLevel = 100;
            }
            else if (mvoltsInt > 499 && mvoltsInt < 701) {
                mvoltsInt = mvoltsInt - 500;
                batteryLevel = mvoltsInt / 2;
            }

            var newBatteryLevel;
            if (batteryLevel % 1 === 0) {
                newBatteryLevel = batteryLevel + ",0 %";
            }
            else {
                newBatteryLevel = batteryLevel.toString().replace(".", ",") + " %";
            }
            mvolts.innerHTML = newBatteryLevel;   

            // HUMIDITY
            var humid = document.getElementById(roomID + "roomHumid");
            var newHumid;

            if (indata.data.humidity !== 100) {
                if (humid % 1 === 0) {
                    newHumid = indata.data.humidity + ",0 %";
                }
                else {
                    newHumid = indata.data.humidity.toString().replace(".", ",") + " %";
                }
                humid.innerHTML = newHumid;
            }

            // SIGNAL
            var rssi = document.getElementById(roomID + "roomSignal");
            var rssiINT = parseInt(indata.data.rssi, 10);

            // 1 BARS
            if (rssiINT < -111) {
                document.getElementById(roomID + "roomSignal1").classList.remove("hide");
                document.getElementById(roomID + "roomSignal2").classList.add("hide");
                document.getElementById(roomID + "roomSignal3").classList.add("hide");
            }

            // 3 BARS
            else if (rssiINT > -90) {
                document.getElementById(roomID + "roomSignal1").classList.add("hide");
                document.getElementById(roomID + "roomSignal2").classList.add("hide");
                document.getElementById(roomID + "roomSignal3").classList.remove("hide");
            }

            // 2 BARS
            else if (rssiINT > -110 && rssi < -91) {
                document.getElementById(roomID + "roomSignal1").classList.add("hide");
                document.getElementById(roomID + "roomSignal2").classList.remove("hide");
                document.getElementById(roomID + "roomSignal3").classList.add("hide");
            }

            // TIMESTAMP	
            var timestamp3 = new Date(indata.data.ts);
            var currentDate = new Date();
            var timeDifferential = currentDate - timestamp3;
            var diffMins = Math.round(((timeDifferential % 86400000) % 3600000) / 60000); // minutes

            var timeStampLabel = document.getElementById(roomID + "roomTime");

            if (timeDifferential < 3600000) {
                if (diffMins === 1) {

                    timeStampLabel.innerHTML = diffMins + " Minute";
                }
                else {
                    timeStampLabel.innerHTML = diffMins + " Minuten";
                }
            }
            else {
                timeStampLabel.innerHTML = "> 60 Minuten";
                document.getElementById("tr"+roomID).style.opacity = "0.4";
            }
        }

        client.on('close', function () {            
            console.log("Raumtemperatur DISCONNECTED")        
        })

    return () => client.end(); // <-- kill on unmount
    }, []) // <-- empty dependency array

  return (
    <div className="module-wrapper">
        <div className="distancer multi-card-wrapper">
            {/* // SUBNAVIGATION */}
            <div className="subnav">
                <div className="fltl">
                    <Link to="/Devices">
                        <Button variant="contained" color="primary" className="back-button" startIcon={<ArrowBackIosTwoToneIcon/>}>Geräte</Button>
                    </Link>
                </div>
                <div className="fltr">
                    <Button id="roomTempOnline" variant="outlined" color="primary" className="online fltr hide" disableElevation disabled startIcon={<LinkIcon/>}>Verbunden</Button>
                    <Button id="roomTempOffline" variant="outlined" color="primary" className="offline fltr" disableElevation disabled startIcon={<LinkOffIcon/>}>Verbindung</Button>
                </div>
                <div className="spacer"></div>                     
            </div>
            {/* END SUBNAVIGATION*/}  
            <div className="outer-title">Raumtemperatur</div>      

            <Card>
                <Card.Header>
                    <div className="card-title">Haus 1</div> 
                </Card.Header>
                <Card.Body>
                    <table className="table">
                    <thead>
                        <tr> 
                            <th>sID</th>
                            <th>ID</th>
                            <th>Raum</th>									
                            <th>Temperatur</th>
                            <th>Luftfeuchtigkeit</th>
                            <th>Batterie</th>
                            <th>Zuletzt vor</th>
                            <th>Signal</th>									
                        </tr>
                    </thead>
                    <tbody>
                        <tr id="tr101">
                            <td id="101sensorID"></td>
                            <td id="101roomID">1.0.1</td>
                            <td id="101roomName">Seminarraum 1</td>									
                            <td id="101roomTemp"></td>
                            <td id="101roomHumid"></td>
                            <td id="101roomBattery"></td>
                            <td id="101roomTime"></td>
                            <td id="101roomSignal">
                                <img id="101roomSignal1" className="signalBars hide" src={signalBar1}/>
                                <img id="101roomSignal2"className="signalBars hide"  src={signalBar2}/>
                                <img id="101roomSignal3" className="signalBars hide"  src={signalBar3}/>
                            </td>									
                        </tr>
                        <tr id="tr102">
                            <td id="102sensorID"></td>
                            <td id="102roomID">1.0.2</td>
                            <td id="102roomName">Seminarraum 2</td>									
                            <td id="102roomTemp"></td>
                            <td id="102roomHumid"></td>
                            <td id="102roomBattery"></td>
                            <td id="102roomTime"></td>
                            <td id="102roomSignal">
                                <img id="102roomSignal1" className="signalBars hide" src={signalBar1}/>
                                <img id="102roomSignal2"className="signalBars hide"  src={signalBar2}/>
                                <img id="102roomSignal3" className="signalBars hide"  src={signalBar3}/>                      
                                </td>									
                        </tr>
                        <tr id="tr105">
                            <td id="105sensorID"></td>
                            <td id="105roomID">1.0.5</td>
                            <td id="105roomName">Cateringküche</td>									
                            <td id="105roomTemp"></td>
                            <td id="105roomHumid"></td>
                            <td id="105roomBattery"></td>
                            <td id="105roomTime"></td>
                            <td id="105roomSignal">
                                <img id="105roomSignal1" className="signalBars hide" src={signalBar1}/>
                                <img id="105roomSignal2"className="signalBars hide"  src={signalBar2}/>
                                <img id="105roomSignal3" className="signalBars hide"  src={signalBar3}/>                       
                            </td>									
                        </tr>
                        <tr id="tr106">
                            <td id="106sensorID"></td>
                            <td id="106roomID">1.0.6</td>
                            <td id="106roomName">Bewirtung</td>									
                            <td id="106roomTemp"></td>
                            <td id="106roomHumid"></td>
                            <td id="106roomBattery"></td>
                            <td id="106roomTime"></td>
                            <td id="106roomSignal">
                                <img id="106roomSignal1" className="signalBars hide" src={signalBar1}/>
                                <img id="106roomSignal2"className="signalBars hide"  src={signalBar2}/>
                                <img id="106roomSignal3" className="signalBars hide"  src={signalBar3}/>                
                            </td>									
                        </tr>
                        <tr id="tr109">
                            <td id="109sensorID"></td>
                            <td id="109roomID">1.0.9</td>
                            <td id="109roomName">WC Herren</td>									
                            <td id="109roomTemp"></td>
                            <td id="109roomHumid"></td>
                            <td id="109roomBattery"></td>
                            <td id="109roomTime"></td>
                            <td id="109roomSignal">
                                <img id="109roomSignal1" className="signalBars hide" src={signalBar1}/>
                                <img id="109roomSignal2"className="signalBars hide"  src={signalBar2}/>
                                <img id="109roomSignal3" className="signalBars hide"  src={signalBar3}/>                           
                            </td>									
                        </tr>
                        <tr id="tr1010">
                            <td id="1010sensorID"></td>
                            <td id="1010roomID">1.0.10</td>
                            <td id="1010roomName">Flur</td>									
                            <td id="1010roomTemp"></td>
                            <td id="1010roomHumid"></td>
                            <td id="1010roomBattery"></td>
                            <td id="1010roomTime"></td>
                            <td id="1010roomSignal">
                                <img id="1010roomSignal1" className="signalBars hide" src={signalBar1}/>
                                <img id="1010roomSignal2"className="signalBars hide"  src={signalBar2}/>
                                <img id="1010roomSignal3" className="signalBars hide"  src={signalBar3}/>           
                            </td>									
                        </tr>
                    </tbody>
                </table>
                </Card.Body>
            </Card>
                      
            <Card>
                <Card.Header>
                    <div className="card-title">Haus 2</div> 
                </Card.Header>                
                <Card.Body>
                    <table className="table">
                    <thead>
                        <tr>
                            <th>sID</th>
                            <th >ID</th>
                            <th>Raum</th>
                            <th>Temperatur</th>
                            <th>Luftfeuchtigkeit</th>
                            <th>Batterie</th>
                            <th>Zuletzt vor</th>
                            <th>Signal</th>									
                        </tr>
                    </thead>
                    <tbody>
                        <tr id="tr207">
                            <td id="207sensorID"></td>
                            <td id="207roomID">2.0.7</td>
                            <td id="207roomName">Heizungszentrale</td>									
                            <td id="207roomTemp"></td>
                            <td id="207roomHumid"></td>
                            <td id="207roomBattery"></td>
                            <td id="207roomTime"></td>
                            <td id="207roomSignal">
                                <img id="207roomSignal1" className="signalBars hide" src={signalBar1}/>
                                <img id="207roomSignal2"className="signalBars hide"  src={signalBar2}/>
                                <img id="207roomSignal3" className="signalBars hide"  src={signalBar3}/>                           
                                </td>									
                        </tr>
                        <tr id="tr211">
                            <td id="211sensorID"></td>
                            <td id="211roomID">2.1.1</td>
                            <td id="211roomName">Büro Duensing</td>									
                            <td id="211roomTemp"></td>
                            <td id="211roomHumid"></td>
                            <td id="211roomBattery"></td>
                            <td id="211roomTime"></td>
                            <td id="211roomSignal">
                                <img id="211roomSignal1" className="signalBars hide" src={signalBar1}/>
                                <img id="211roomSignal2"className="signalBars hide"  src={signalBar2}/>
                                <img id="211roomSignal3" className="signalBars hide"  src={signalBar3}/>                           
                            </td>									
                        </tr>
                        <tr id="tr2110">
                            <td id="2110sensorID"></td>
                            <td id="2110roomID">2.1.10</td>
                            <td id="2110roomName">Serverraum</td>									
                            <td id="2110roomTemp"></td>
                            <td id="2110roomHumid"></td>
                            <td id="2110roomBattery"></td>
                            <td id="2110roomTime"></td>
                            <td id="2110roomSignal">
                                <img id="2110roomSignal1" className="signalBars hide" src={signalBar1}/>
                                <img id="2110roomSignal2"className="signalBars hide"  src={signalBar2}/>
                                <img id="2110roomSignal3" className="signalBars hide"  src={signalBar3}/>                      
                                </td>									
                        </tr>
                        <tr id="tr2112">
                            <td id="2112sensorID"></td>
                            <td id="2112roomID">2.1.12</td>
                            <td id="2112roomName">Flur</td>									
                            <td id="2112roomTemp"></td>
                            <td id="2112roomHumid"></td>
                            <td id="2112roomBattery"></td>
                            <td id="2112roomTime"></td>
                            <td id="2112roomSignal">
                                <img id="2112roomSignal1" className="signalBars hide" src={signalBar1}/>
                                <img id="2112roomSignal2"className="signalBars hide"  src={signalBar2}/>
                                <img id="2112roomSignal3" className="signalBars hide"  src={signalBar3}/>                  
                                </td>									
                        </tr>
                        <tr id="tr226">
                            <td id="226sensorID"></td>
                            <td id="226roomID">2.2.6</td>
                            <td id="226roomName">Buchhaltung</td>									
                            <td id="226roomTemp"></td>
                            <td id="226roomHumid"></td>
                            <td id="226roomBattery"></td>
                            <td id="226roomTime"></td>
                            <td id="226roomSignal">
                                <img id="226roomSignal1" className="signalBars hide" src={signalBar1}/>
                                <img id="226roomSignal2"className="signalBars hide"  src={signalBar2}/>
                                <img id="226roomSignal3" className="signalBars hide"  src={signalBar3}/>                 
                            </td>									
                        </tr>
                    </tbody>
                    </table>
                </Card.Body>
            </Card>
            <Card>
                <Card.Header>
                    <div className="card-title">Haus 3</div>  
                </Card.Header>                
                <Card.Body>
                    <table className="table">
                    <thead>
                        <tr>
                            <th>sID</th>
                            <th>ID</th>
                            <th>Raum</th>
                            <th>Temperatur</th>
                            <th>Luftfeuchtigkeit</th>
                            <th>Batterie</th>
                            <th>Zuletzt vor</th>
                            <th>Signal</th>									
                        </tr>
                    </thead>
                    <tbody>
                        <tr id="tr301">
                            <td id="301sensorID"></td>
                            <td id="301roomID">3.0.1</td>
                            <td id="301roomName">Treppenhaus</td>									
                            <td id="301roomTemp"></td>
                            <td id="301roomHumid"></td>
                            <td id="301roomBattery"></td>
                            <td id="301roomTime"></td>
                            <td id="301roomSignal">
                                <img id="301roomSignal1" className="signalBars hide" src={signalBar1}/>
                                <img id="301roomSignal2"className="signalBars hide"  src={signalBar2}/>
                                <img id="301roomSignal3" className="signalBars hide"  src={signalBar3}/>                        
                            </td>									
                        </tr>
                        <tr id="tr312">
                            <td id="312sensorID"></td>
                            <td id="312roomID">3.1.2</td>
                            <td id="312roomName">Smarthome/ZVconnect</td>									
                            <td id="312roomTemp"></td>
                            <td id="312roomHumid"></td>
                            <td id="312roomBattery"></td>
                            <td id="312roomTime"></td>
                            <td id="312roomSignal">
                                <img id="312roomSignal1" className="signalBars hide" src={signalBar1}/>
                                <img id="312roomSignal2"className="signalBars hide"  src={signalBar2}/>
                                <img id="312roomSignal3" className="signalBars hide"  src={signalBar3}/>       
                            </td>									
                        </tr>
                        <tr id="tr322">
                            <td id="322sensorID"></td>
                            <td id="322roomID">3.2.2</td>
                            <td id="322roomName">BitSign</td>									
                            <td id="322roomTemp"></td>
                            <td id="322roomHumid"></td>
                            <td id="322roomBattery"></td>
                            <td id="322roomTime"></td>
                            <td id="322roomSignal">
                                <img id="322roomSignal1" className="signalBars hide" src={signalBar1}/>
                                <img id="322roomSignal2"className="signalBars hide"  src={signalBar2}/>
                                <img id="322roomSignal3" className="signalBars hide"  src={signalBar3}/>                       
                            </td>									
                        </tr>
                    </tbody>
                    </table>
                </Card.Body>
            </Card>            
        </div>
    </div>
  );
}

export default Raumtemperatur;