import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {endOfToday, set } from 'date-fns';
import TimeRange from 'react-timeline-range-slider';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Icon from "../../Icons";

import ArrowBackIosTwoToneIcon from '@material-ui/icons/ArrowBackIosTwoTone';
import Button from '@material-ui/core/Button';

import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const now = new Date();
const getTodayAtSpecificHour = (hour = 12, minutes = 0) =>
  set(now, { hours: hour, minutes: minutes, seconds: 0, milliseconds: 0 });


//Standardwerte der selektierten Zeitskala
var selectedStartAM = getTodayAtSpecificHour(5);
var selectedEndAM = getTodayAtSpecificHour(5);

const selectedStartPM = getTodayAtSpecificHour(15);
const selectedEndPM = getTodayAtSpecificHour(15);
  
// Range der Zeitskalen für Morgens und Abends
const startTimeAM = getTodayAtSpecificHour(5);
const endTimeAM = getTodayAtSpecificHour(12);

const startTimePM = getTodayAtSpecificHour(15);
const endTimePM = getTodayAtSpecificHour(22);

const disabledIntervals = [ // not possible to set timerange inside these intervals
    //{ start: getTodayAtSpecificHour(16), end: getTodayAtSpecificHour(17) },
    //{ start: getTodayAtSpecificHour(7), end: getTodayAtSpecificHour(12) },
    //{ start: getTodayAtSpecificHour(20), end: getTodayAtSpecificHour(24) },
];

var arrayOfDOBX1 = [0,0,0,0,0,0];
var arrayOfDOBX2 = [0,0,0,0,0,0];

var light11;
var light12;
var light13;

var light21;
var light23;
var light24;

function Lights() {

    
    
    const [error, setError] = useState(false);
    const errorHandler = ({ error }) => setError(error);

    const [selectedIntervalSetAllAM, setSelectedIntervalSetAllAM] = useState([selectedStartAM, selectedEndAM]);
    const [selectedIntervalSetAllPM, setSelectedIntervalSetAllPM] = useState([selectedStartPM, selectedEndPM]);

    const [switchHouse, setSwitchHouse] = useState(false);

    //Haus 1 States
    const [selectedIntervalSetMonAMH1, setSelectedIntervalSetMonAMH1] = useState([selectedStartAM, selectedEndAM]);
    const [selectedIntervalSetMonPMH1, setSelectedIntervalSetMonPMH1] = useState([selectedStartPM, selectedEndPM]);

    const [selectedIntervalSetTueAMH1, setSelectedIntervalSetTueAMH1] = useState([selectedStartAM, selectedEndAM]);
    const [selectedIntervalSetTuePMH1, setSelectedIntervalSetTuePMH1] = useState([selectedStartPM, selectedEndPM]);

    const [selectedIntervalSetWedAMH1, setSelectedIntervalSetWedAMH1] = useState([selectedStartAM, selectedEndAM]);
    const [selectedIntervalSetWedPMH1, setSelectedIntervalSetWedPMH1] = useState([selectedStartPM, selectedEndPM]);

    const [selectedIntervalSetThuAMH1, setSelectedIntervalSetThuAMH1] = useState([selectedStartAM, selectedEndAM]);
    const [selectedIntervalSetThuPMH1, setSelectedIntervalSetThuPMH1] = useState([selectedStartPM, selectedEndPM]);

    const [selectedIntervalSetFriAMH1, setSelectedIntervalSetFriAMH1] = useState([selectedStartAM, selectedEndAM]);
    const [selectedIntervalSetFriPMH1, setSelectedIntervalSetFriPMH1] = useState([selectedStartPM, selectedEndPM]);

    const [disableMonH1, setDisableMonH1] = useState(false);
    const [disableTueH1, setDisableTueH1] = useState(false);
    const [disableWedH1, setDisableWedH1] = useState(false);
    const [disableThuH1, setDisableThuH1] = useState(false);
    const [disableFriH1, setDisableFriH1] = useState(false);

    //Haus 2 States
    const [selectedIntervalSetMonAMH2, setSelectedIntervalSetMonAMH2] = useState([selectedStartAM, selectedEndAM]);
    const [selectedIntervalSetMonPMH2, setSelectedIntervalSetMonPMH2] = useState([selectedStartPM, selectedEndPM]);

    const [selectedIntervalSetTueAMH2, setSelectedIntervalSetTueAMH2] = useState([selectedStartAM, selectedEndAM]);
    const [selectedIntervalSetTuePMH2, setSelectedIntervalSetTuePMH2] = useState([selectedStartPM, selectedEndPM]);

    const [selectedIntervalSetWedAMH2, setSelectedIntervalSetWedAMH2] = useState([selectedStartAM, selectedEndAM]);
    const [selectedIntervalSetWedPMH2, setSelectedIntervalSetWedPMH2] = useState([selectedStartPM, selectedEndPM]);

    const [selectedIntervalSetThuAMH2, setSelectedIntervalSetThuAMH2] = useState([selectedStartAM, selectedEndAM]);
    const [selectedIntervalSetThuPMH2, setSelectedIntervalSetThuPMH2] = useState([selectedStartPM, selectedEndPM]);

    const [selectedIntervalSetFriAMH2, setSelectedIntervalSetFriAMH2] = useState([selectedStartAM, selectedEndAM]);
    const [selectedIntervalSetFriPMH2, setSelectedIntervalSetFriPMH2] = useState([selectedStartPM, selectedEndPM]);

    const [disableMonH2, setDisableMonH2] = useState(false);
    const [disableTueH2, setDisableTueH2] = useState(false);
    const [disableWedH2, setDisableWedH2] = useState(false);
    const [disableThuH2, setDisableThuH2] = useState(false);
    const [disableFriH2, setDisableFriH2] = useState(false);


    

    



    const onChangeCallbackAllAM = (selectedIntervalSetAllAM) => {
        setSelectedIntervalSetAllAM(selectedIntervalSetAllAM);
        document.getElementById("showAllDayAM").innerHTML = selectedIntervalSetAllAM[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetAllAM[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
    }
    const onChangeCallbackAllPM = (selectedIntervalSetAllPM) => {
        setSelectedIntervalSetAllPM(selectedIntervalSetAllPM);
        document.getElementById("showAllDayPM").innerHTML = selectedIntervalSetAllPM[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetAllPM[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
    }


    // Calbacks Sliders Haus 1
    const onChangeCallbackMonAMH1 = (selectedIntervalSetMonAMH1) => {
        setSelectedIntervalSetMonAMH1(selectedIntervalSetMonAMH1);
        selectedIntervalSetMonAMH1[0]?document.getElementById("showMonDay").innerHTML = selectedIntervalSetMonAMH1[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetMonAMH1[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " und " + selectedIntervalSetMonPMH1[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetMonPMH1[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) :document.getElementById("showMonDay").innerHTML = " " ;
    }

    const onChangeCallbackMonPMH1 = (selectedIntervalSetMonPMH1) => {
        setSelectedIntervalSetMonPMH1(selectedIntervalSetMonPMH1);
        document.getElementById("showMonDay").innerHTML = selectedIntervalSetMonAMH1[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetMonAMH1[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " und " + selectedIntervalSetMonPMH1[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetMonPMH1[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
    }

    const onChangeCallbackTueAMH1 = (selectedIntervalSetTueAMH1) => {
        setSelectedIntervalSetTueAMH1(selectedIntervalSetTueAMH1);
        document.getElementById("showTueDay").innerHTML = selectedIntervalSetTueAMH1[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetTueAMH1[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " und " + selectedIntervalSetTuePMH1[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetTuePMH1[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});

    }
    const onChangeCallbackTuePMH1 = (selectedIntervalSetTuePMH1) => {
        setSelectedIntervalSetTuePMH1(selectedIntervalSetTuePMH1);
        document.getElementById("showTueDay").innerHTML = selectedIntervalSetTueAMH1[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetTueAMH1[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " und " + selectedIntervalSetTuePMH1[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetTuePMH1[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
    }

    const onChangeCallbackWedAMH1 = (selectedIntervalSetWedAMH1) => {
        setSelectedIntervalSetWedAMH1(selectedIntervalSetWedAMH1);
        document.getElementById("showWedDay").innerHTML = selectedIntervalSetWedAMH1[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetWedAMH1[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " und " + selectedIntervalSetWedPMH1[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetWedPMH1[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
    }

    const onChangeCallbackWedPMH1 = (selectedIntervalSetWedPMH1) => {
        setSelectedIntervalSetWedPMH1(selectedIntervalSetWedPMH1);
        document.getElementById("showWedDay").innerHTML = selectedIntervalSetWedAMH1[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetWedAMH1[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " und " + selectedIntervalSetWedPMH1[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetWedPMH1[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
    }

    const onChangeCallbackThuAMH1 = (selectedIntervalSetThuAMH1) => {
        setSelectedIntervalSetThuAMH1(selectedIntervalSetThuAMH1);
        document.getElementById("showThuDay").innerHTML = selectedIntervalSetThuAMH1[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetThuAMH1[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " und " + selectedIntervalSetThuPMH1[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetThuPMH1[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
    }

    const onChangeCallbackThuPMH1 = (selectedIntervalSetThuPMH1) => {
        setSelectedIntervalSetThuPMH1(selectedIntervalSetThuPMH1);
        document.getElementById("showThuDay").innerHTML = selectedIntervalSetThuAMH1[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetThuAMH1[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " und " + selectedIntervalSetThuPMH1[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetThuPMH1[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
    }

    const onChangeCallbackFriAMH1 = (selectedIntervalSetFriAMH1) => {
        setSelectedIntervalSetFriAMH1(selectedIntervalSetFriAMH1);
        document.getElementById("showFriDay").innerHTML = selectedIntervalSetFriAMH1[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetFriAMH1[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " und " + selectedIntervalSetFriPMH1[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetFriPMH1[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
    }

    const onChangeCallbackFriPMH1 = (selectedIntervalSetFriPMH1) => {
        setSelectedIntervalSetFriPMH1(selectedIntervalSetFriPMH1);
        document.getElementById("showFriDay").innerHTML = selectedIntervalSetFriAMH1[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetFriAMH1[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " und " + selectedIntervalSetFriPMH1[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetFriPMH1[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
    }



    // Callbacks Sliders Haus 2
    const onChangeCallbackMonAMH2 = (selectedIntervalSetMonAMH2) => {
        setSelectedIntervalSetMonAMH2(selectedIntervalSetMonAMH2);
        selectedIntervalSetMonAMH2[0]?document.getElementById("showMonDayH2").innerHTML = selectedIntervalSetMonAMH2[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetMonAMH2[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " und " + selectedIntervalSetMonPMH2[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetMonPMH2[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) :document.getElementById("showMonDayH2").innerHTML = " " ;
    }

    const onChangeCallbackMonPMH2 = (selectedIntervalSetMonPMH2) => {
        setSelectedIntervalSetMonPMH2(selectedIntervalSetMonPMH2);
        document.getElementById("showMonDayH2").innerHTML = selectedIntervalSetMonAMH2[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetMonAMH2[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " und " + selectedIntervalSetMonPMH2[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetMonPMH2[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
    }

    const onChangeCallbackTueAMH2 = (selectedIntervalSetTueAMH2) => {
        setSelectedIntervalSetTueAMH2(selectedIntervalSetTueAMH2);
        document.getElementById("showTueDayH2").innerHTML = selectedIntervalSetTueAMH2[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetTueAMH2[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " und " + selectedIntervalSetTuePMH2[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetTuePMH2[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});

    }
    const onChangeCallbackTuePMH2 = (selectedIntervalSetTuePMH2) => {
        setSelectedIntervalSetTuePMH2(selectedIntervalSetTuePMH2);
        document.getElementById("showTueDayH2").innerHTML = selectedIntervalSetTueAMH2[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetTueAMH2[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " und " + selectedIntervalSetTuePMH2[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetTuePMH2[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
    }

    const onChangeCallbackWedAMH2 = (selectedIntervalSetWedAMH2) => {
        setSelectedIntervalSetWedAMH2(selectedIntervalSetWedAMH2);
        document.getElementById("showWedDayH2").innerHTML = selectedIntervalSetWedAMH2[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetWedAMH2[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " und " + selectedIntervalSetWedPMH2[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetWedPMH2[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
    }

    const onChangeCallbackWedPMH2 = (selectedIntervalSetWedPMH2) => {
        setSelectedIntervalSetWedPMH2(selectedIntervalSetWedPMH2);
        document.getElementById("showWedDayH2").innerHTML = selectedIntervalSetWedAMH2[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetWedAMH2[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " und " + selectedIntervalSetWedPMH2[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetWedPMH2[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
    }

    const onChangeCallbackThuAMH2 = (selectedIntervalSetThuAMH2) => {
        setSelectedIntervalSetThuAMH2(selectedIntervalSetThuAMH2);
        document.getElementById("showThuDayH2").innerHTML = selectedIntervalSetThuAMH2[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetThuAMH2[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " und " + selectedIntervalSetThuPMH2[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetThuPMH2[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
    }

    const onChangeCallbackThuPMH2 = (selectedIntervalSetThuPMH2) => {
        setSelectedIntervalSetThuPMH2(selectedIntervalSetThuPMH2);
        document.getElementById("showThuDayH2").innerHTML = selectedIntervalSetThuAMH2[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetThuAMH2[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " und " + selectedIntervalSetThuPMH2[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetThuPMH2[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
    }

    const onChangeCallbackFriAMH2 = (selectedIntervalSetFriAMH2) => {
        setSelectedIntervalSetFriAMH2(selectedIntervalSetFriAMH2);
        document.getElementById("showFriDayH2").innerHTML = selectedIntervalSetFriAMH2[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetFriAMH2[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " und " + selectedIntervalSetFriPMH2[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetFriPMH2[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
    }

    const onChangeCallbackFriPMH2 = (selectedIntervalSetFriPMH2) => {
        setSelectedIntervalSetFriPMH2(selectedIntervalSetFriPMH2);
        document.getElementById("showFriDayH2").innerHTML = selectedIntervalSetFriAMH2[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetFriAMH2[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " und " + selectedIntervalSetFriPMH2[0].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'}) + " - " + selectedIntervalSetFriPMH2[1].toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
    }



    const [reqSched, setReqSched] = useState([0]);

    //useEffect executes code upon mounting of the component    
    useEffect(()=>{
        // do stuff here...
        var mqtt = require('mqtt')
        var Broker = "mqtts://mqtt.consoft.de:8001";
        var options = {
            protocol: 'mqtts',
            clientId: 'EZGWebsiban' + Math.floor(Math.random() * 1000)
        };
        //var MasterTopic = "Dehoust/DehoustConnect/240AC416A994";
        var Topic1 = "Dehoust/DOBOXES/ZVC/OUT/DOBX1";
        var Topic2 = "Dehoust/DOBOXES/ZVC/OUT/DOBX2";
        var TopicGetSched = "Dehoust/DOBOXES/ZVC/IN/getSched";

        var client  = mqtt.connect(Broker, options);

        client.on('connect', function () {


            console.log('Lights CONNECTED as ' + options.clientId);            
            client.subscribe(Topic1, function (err) {;
                if (!err) {
                console.log('SUBBED'+ Topic1);                             
                }
            })                       
            client.subscribe(Topic2, function (err) {;
                if (!err) {
                console.log('SUBBED' + Topic2);                             
                }
            })        
            client.subscribe(TopicGetSched, function (err) {;
                if (!err) {
                console.log('SUBBED' + TopicGetSched);
                publishReqSched();                        
                }
            })   
        })

        client.on('message', function (topic, message) {
        // message is Buffer  

        if (topic.endsWith("getSched")) {

            var lightsOnlineIndicator = document.getElementById("lightsOnline");
            var lightsOfflineIndicator = document.getElementById("lightsOffline");

            if (lightsOnlineIndicator !=  null) {
                lightsOfflineIndicator.classList.add('hide');   
                lightsOnlineIndicator.classList.remove('hide'); 
            }
            // console.log("getSchedIncoming");
            // console.log(message.toString());
            parseReqSched(message);
        }
 
        if (topic.endsWith("DOBX1")) {
            console.log("DOBX1 data incoming");
            var dobox1settings = message;
            console.log("DOBX1 says: " + dobox1settings);
            arrayOfDOBX1 = Array.from(String(dobox1settings), Number);
            console.log(arrayOfDOBX1);
    
            //LIGHT 1
            if (arrayOfDOBX1[0] === 0) {
                light11 = 0;
                document.getElementById("light-1-1").checked = false;
            }
            if (arrayOfDOBX1[0] === 1) {
                light11 = 1;
                document.getElementById("light-1-1").checked = true;
            }
            //LIGHT 2
            if (arrayOfDOBX1[1] === 0) {
                light12 = 0;
                document.getElementById("light-1-2").checked = false;
            }
            if (arrayOfDOBX1[1] === 1) {
                light12 = 1;
                document.getElementById("light-1-2").checked = true;
            }
            //LIGHT 3
            if (arrayOfDOBX1[2] === 0) {
                light13 = 0;
                document.getElementById("light-1-3").checked = false;
            }
            if (arrayOfDOBX1[2] === 1) {
                light13 = 1;
                document.getElementById("light-1-3").checked = true;
            }
            //LIGHT 4
            if (arrayOfDOBX1[3] === 0) {
                document.getElementById("light-1-4").checked = false;
            }
            if (arrayOfDOBX1[3] === 1) {
                document.getElementById("light-1-4").checked = true;
            }
            //CHECK FOR MAIN 1
            if (light11 === 1 && light12 === 1 && light13 === 1) {
                // SET MAIN 1 TRUE
                document.getElementById("group-1").checked = true;
            }
    
        }
    
        if (topic.endsWith("DOBX2")) {
            console.log("DOBX2 data incoming");
            var dobox2settings = message;
            console.log("DOBX2 says: " + dobox2settings);
            arrayOfDOBX2 = Array.from(String(dobox2settings), Number);
            console.log(arrayOfDOBX2);
    
            //LIGHT 1
            if (arrayOfDOBX2[0] === 0) {
                light21 = 0;
                document.getElementById("light-2-1").checked = false;
            }
            if (arrayOfDOBX2[0] === 1) {
                light21 = 1;
                document.getElementById("light-2-1").checked = true;
            }
            //LIGHT 2
            if (arrayOfDOBX2[1] === 0) {
                document.getElementById("light-2-2").checked = false;
            }
            if (arrayOfDOBX2[1] === 1) {
                document.getElementById("light-2-2").checked = true;
            }
            //LIGHT 3
            if (arrayOfDOBX2[2] === 0) {
                light23 = 0;
                document.getElementById("light-2-3").checked = false;
            }
            if (arrayOfDOBX2[2] === 1) {
                light23 = 1;
                document.getElementById("light-2-3").checked = true;
            }
            //LIGHT 4
            if (arrayOfDOBX2[3] === 0) {
                light24 = 0;
                document.getElementById("light-2-4").checked = false;
            }
            if (arrayOfDOBX2[3] === 1) {
                light24 = 1;
                document.getElementById("light-2-4").checked = true;
            }
    
            //CHECK FOR MAIN 2
            if (light21 === 1 && light23 === 1 && light24 === 1) {
                // SET MAIN 2 TRUE
                document.getElementById("group-2").checked = true;
            }
            
        }
    
    })

    const checkboxLight11 = document.getElementById('light-1-1');
    checkboxLight11.addEventListener('change', (event) => {
        if (event.target.checked) {
            arrayOfDOBX1[0] = 1;
            console.log("Changed to " + arrayOfDOBX1);
            publishDOBX1();
        } else {
            arrayOfDOBX1[0] = 0;
            console.log("Changed to " + arrayOfDOBX1);
            publishDOBX1();
        }
    });
    
    const checkboxLight12 = document.getElementById('light-1-2');
    checkboxLight12.addEventListener('change', (event) => {
        if (event.target.checked) {
            arrayOfDOBX1[1] = 1;
            console.log("Changed to " + arrayOfDOBX1);
            publishDOBX1();
        } else {
            arrayOfDOBX1[1] = 0;
            console.log("Changed to " + arrayOfDOBX1);
            publishDOBX1();
        }
    });
    
    const checkboxLight13 = document.getElementById('light-1-3');
    checkboxLight13.addEventListener('change', (event) => {
        if (event.target.checked) {
            arrayOfDOBX1[2] = 1;
            console.log("Changed to " + arrayOfDOBX1);
            publishDOBX1();
        } else {
            arrayOfDOBX1[2] = 0;
            console.log("Changed to " + arrayOfDOBX1);
            publishDOBX1();
        }
    });

    const checkboxLight14 = document.getElementById('light-1-4');
    checkboxLight14.addEventListener('change', (event) => {
	if (event.target.checked) {
		arrayOfDOBX1[3] = 1;
		console.log("Changed to " + arrayOfDOBX1);
		publishDOBX1();
	} else {
		arrayOfDOBX1[3] = 0;
		console.log("Changed to " + arrayOfDOBX1);
		publishDOBX1();
	}
    });

        
    // GROUP 1 Switch
    
    const checkboxGroup1 = document.getElementById('group-1');
    checkboxGroup1.addEventListener('change', (event) => {
        if (event.target.checked) {
            arrayOfDOBX1 = Array.from(String("111111"), Number);
            console.log("Changed to " + arrayOfDOBX1);
            publishDOBX1();
        } else {
            arrayOfDOBX1 = Array.from(String("000000"), Number);
            console.log("Changed to " + arrayOfDOBX1);
            publishDOBX1();
        }
    }); 
    
    // GROUP 2
    
    const checkboxLight21 = document.getElementById('light-2-1');
    checkboxLight21.addEventListener('change', (event) => {
        if (event.target.checked) {
            arrayOfDOBX2[0] = 1;
            console.log("Changed to " + arrayOfDOBX2);
            publishDOBX2();
        } else {
            arrayOfDOBX2[0] = 0;
            console.log("Changed to " + arrayOfDOBX2);
            publishDOBX2();
        }
    });
    
    const checkboxLight22 = document.getElementById('light-2-2');
    checkboxLight22.addEventListener('change', (event) => {
        if (event.target.checked) {
            arrayOfDOBX2[1] = 1;
            console.log("Changed to " + arrayOfDOBX2);
            publishDOBX2();
        } else {
            arrayOfDOBX2[1] = 0;
            console.log("Changed to " + arrayOfDOBX2);
            publishDOBX2();
        }
    });
    
    const checkboxLight23 = document.getElementById('light-2-3');
    checkboxLight23.addEventListener('change', (event) => {
        if (event.target.checked) {
            arrayOfDOBX2[2] = 1;
            console.log("Changed to " + arrayOfDOBX2);
            publishDOBX2();
        } else {
            arrayOfDOBX2[2] = 0;
            console.log("Changed to " + arrayOfDOBX2);
            publishDOBX2();
        }
    });

    const checkboxLight24 = document.getElementById('light-2-4');
    checkboxLight24.addEventListener('change', (event) => {
        if (event.target.checked) {
            arrayOfDOBX2[3] = 1;
            console.log("Changed to " + arrayOfDOBX2);
            publishDOBX2();
        } else {
            arrayOfDOBX2[3] = 0;
            console.log("Changed to " + arrayOfDOBX2);
            publishDOBX2();
        }
    });
        
    // GROUP 2 Switch
    const checkboxGroup2 = document.getElementById('group-2');
    checkboxGroup2.addEventListener('change', (event) => {
        if (event.target.checked) {
            arrayOfDOBX2 = Array.from(String("111111"), Number);
            console.log("Changed to " + arrayOfDOBX2);
            publishDOBX2();
        } else {
            arrayOfDOBX2 = Array.from(String("000000"), Number);
            console.log("Changed to " + arrayOfDOBX2);
            publishDOBX2();
        }
    });    
    
    function publishDOBX1() {
        var DOBX1asString = arrayOfDOBX1[0].toString() + arrayOfDOBX1[1].toString() + arrayOfDOBX1[2].toString() + arrayOfDOBX1[3].toString() + arrayOfDOBX1[4].toString() + arrayOfDOBX1[5].toString();
        console.log("Outgoing String DOBX1: " + DOBX1asString);
        client.publish("Dehoust/DOBOXES/ZVC/OUT/DOBX1", DOBX1asString.toString(), {retain: true});
    };
    
    function publishDOBX2() {
        var DOBX2asString = arrayOfDOBX2[0].toString() + arrayOfDOBX2[1].toString() + arrayOfDOBX2[2].toString() + arrayOfDOBX2[3].toString() + arrayOfDOBX2[4].toString() + arrayOfDOBX2[5].toString();
        console.log("Outgoing String DOBX2: " + DOBX2asString);
        client.publish("Dehoust/DOBOXES/ZVC/OUT/DOBX2", DOBX2asString.toString(), {retain: true});
    };   

    function publishReqSched() {
        console.log("Requesting Schedule...");
        client.publish("Dehoust/DOBOXES/ZVC/OUT/reqSched", " ", {retain: false});
    };

    function publishSetSched(schedJson) {
        console.log("Sending New Schedule...");
        client.publish("Dehoust/DOBOXES/ZVC/OUT/setSched", JSON.stringify(schedJson), {retain: false});

        toggleSendingAnimation()
        delay(2000).then(() => publishReqSched());
    }

    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }


    // BUTTON PROGRESS ANIMATION
   
    const btnwpl =  document.getElementById('setAllBtn');

    function toggleSendingAnimation(){
        btnwpl.classList.add("active");
        setTimeout(()=>{
            btnwpl.classList.remove("active");
        },2000);
    }
      
    function parseReqSched(message) {

       // setReqSched(message.toString());

       var sched = JSON.parse(message);
       setReqSched(JSON.stringify(sched))
       console.log(sched);
       setSetter(sched);
       

       
    };

    // Setzt Setter für values der Zeitstrahlen
    // Setzt Setter für values der Zeitstrahlen
    const setSetter = (sched) =>{
        
        if(sched.sched.length === 0 || sched.sched == undefined ){
            console.log("got no schedule")
            var cards = document.getElementsByClassName("dailyCards")
            for (var x = 0; x < cards.length; x++){
                cards[x].style.opacity = 0.25
            }

        } else {
        if(sched.sched.time == undefined)
        
        //Lord forgive me for what I'm about to code
        //default für "alle Setzen Slider" ist Schedule von Montag
        //setSelectedIntervalSetAllAM([getTodayAtSpecificHour(parseInt(sched.sched[0].time.slice(4,6))+1 ,parseInt(sched.sched[0].time.slice(7,9))), getTodayAtSpecificHour(parseInt(sched.sched[1].time.slice(4,6))+1,parseInt(sched.sched[1].time.slice(7,9)))]);
        //setSelectedIntervalSetAllPM([getTodayAtSpecificHour(parseInt(sched.sched[2].time.slice(4,6))+1,parseInt(sched.sched[2].time.slice(7,9))), getTodayAtSpecificHour(parseInt(sched.sched[3].time.slice(4,6))+1,parseInt(sched.sched[3].time.slice(7,9)))]);

        if(sched.sched[0].time[4]!=="N"){
        //Haus1 setten
        if(sched.sched[0].time != "empty" ){
        setSelectedIntervalSetMonAMH1([getTodayAtSpecificHour(parseInt(sched.sched[0].time.slice(4,6)) ,parseInt(sched.sched[0].time.slice(7,9))), getTodayAtSpecificHour(parseInt(sched.sched[1].time.slice(4,6)),parseInt(sched.sched[1].time.slice(7,9)))]);
        setSelectedIntervalSetMonPMH1([getTodayAtSpecificHour(parseInt(sched.sched[2].time.slice(4,6)),parseInt(sched.sched[2].time.slice(7,9))), getTodayAtSpecificHour(parseInt(sched.sched[3].time.slice(4,6)),parseInt(sched.sched[3].time.slice(7,9)))]);
        var timestartAM = (getTodayAtSpecificHour(parseInt(sched.sched[0].time.slice(4,6)) ,parseInt(sched.sched[0].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        var timeendAM = (getTodayAtSpecificHour(parseInt(sched.sched[1].time.slice(4,6)) ,parseInt(sched.sched[1].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        var timestartPM = (getTodayAtSpecificHour(parseInt(sched.sched[2].time.slice(4,6)) ,parseInt(sched.sched[2].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        var timeendPM = (getTodayAtSpecificHour(parseInt(sched.sched[3].time.slice(4,6)) ,parseInt(sched.sched[3].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        document.getElementById("showMonDay").innerHTML = timestartAM + " - " + timeendAM + " und " + timestartPM + " - " + timeendPM;
        }else{document.getElementById("showMonDay").parentElement.parentElement.parentElement.parentElement.style.opacity = 0.25;}
        
        

        if(sched.sched[4].time != "empty"){
        setSelectedIntervalSetTueAMH1([getTodayAtSpecificHour(parseInt(sched.sched[4].time.slice(4,6)),parseInt(sched.sched[4].time.slice(7,9))), getTodayAtSpecificHour(parseInt(sched.sched[5].time.slice(4,6)),parseInt(sched.sched[5].time.slice(7,9)))]);
        setSelectedIntervalSetTuePMH1([getTodayAtSpecificHour(parseInt(sched.sched[6].time.slice(4,6)),parseInt(sched.sched[6].time.slice(7,9))), getTodayAtSpecificHour(parseInt(sched.sched[7].time.slice(4,6)),parseInt(sched.sched[7].time.slice(7,9)))]);
        timestartAM = (getTodayAtSpecificHour(parseInt(sched.sched[4].time.slice(4,6)) ,parseInt(sched.sched[4].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        timeendAM = (getTodayAtSpecificHour(parseInt(sched.sched[5].time.slice(4,6)) ,parseInt(sched.sched[5].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        timestartPM = (getTodayAtSpecificHour(parseInt(sched.sched[6].time.slice(4,6)) ,parseInt(sched.sched[6].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        timeendPM = (getTodayAtSpecificHour(parseInt(sched.sched[7].time.slice(4,6)) ,parseInt(sched.sched[7].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        document.getElementById("showTueDay").innerHTML = timestartAM + " - " + timeendAM + " und " + timestartPM + " - " + timeendPM;
        }else{document.getElementById("showTueDay").parentElement.parentElement.parentElement.parentElement.style.opacity = 0.25;}

        if(sched.sched[8].time != "empty"){
        setSelectedIntervalSetWedAMH1([getTodayAtSpecificHour(parseInt(sched.sched[8].time.slice(4,6)),parseInt(sched.sched[8].time.slice(7,9))), getTodayAtSpecificHour(parseInt(sched.sched[9].time.slice(4,6)),parseInt(sched.sched[9].time.slice(7,9)))]);
        setSelectedIntervalSetWedPMH1([getTodayAtSpecificHour(parseInt(sched.sched[10].time.slice(4,6)),parseInt(sched.sched[10].time.slice(7,9))), getTodayAtSpecificHour(parseInt(sched.sched[11].time.slice(4,6)),parseInt(sched.sched[11].time.slice(7,9)))]);
        timestartAM = (getTodayAtSpecificHour(parseInt(sched.sched[8].time.slice(4,6)) ,parseInt(sched.sched[8].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        timeendAM = (getTodayAtSpecificHour(parseInt(sched.sched[9].time.slice(4,6)) ,parseInt(sched.sched[9].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        timestartPM = (getTodayAtSpecificHour(parseInt(sched.sched[10].time.slice(4,6)) ,parseInt(sched.sched[10].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        timeendPM = (getTodayAtSpecificHour(parseInt(sched.sched[11].time.slice(4,6)) ,parseInt(sched.sched[11].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        document.getElementById("showWedDay").innerHTML = timestartAM + " - " + timeendAM + " und " + timestartPM + " - " + timeendPM;
        }else{document.getElementById("showWedDay").parentElement.parentElement.parentElement.parentElement.style.opacity = 0.25;}

        if(sched.sched[12].time != "empty"){
        setSelectedIntervalSetThuAMH1([getTodayAtSpecificHour(parseInt(sched.sched[12].time.slice(4,6)),parseInt(sched.sched[12].time.slice(7,9))), getTodayAtSpecificHour(parseInt(sched.sched[13].time.slice(4,6)),parseInt(sched.sched[13].time.slice(7,9)))]);
        setSelectedIntervalSetThuPMH1([getTodayAtSpecificHour(parseInt(sched.sched[14].time.slice(4,6)),parseInt(sched.sched[14].time.slice(7,9))), getTodayAtSpecificHour(parseInt(sched.sched[15].time.slice(4,6)),parseInt(sched.sched[15].time.slice(7,9)))]);
        timestartAM = (getTodayAtSpecificHour(parseInt(sched.sched[12].time.slice(4,6)) ,parseInt(sched.sched[12].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        timeendAM = (getTodayAtSpecificHour(parseInt(sched.sched[13].time.slice(4,6)) ,parseInt(sched.sched[13].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        timestartPM = (getTodayAtSpecificHour(parseInt(sched.sched[14].time.slice(4,6)) ,parseInt(sched.sched[14].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        timeendPM = (getTodayAtSpecificHour(parseInt(sched.sched[15].time.slice(4,6)) ,parseInt(sched.sched[15].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        document.getElementById("showThuDay").innerHTML = timestartAM + " - " + timeendAM + " und " + timestartPM + " - " + timeendPM;
        }else{document.getElementById("showThuDay").parentElement.parentElement.parentElement.parentElement.style.opacity = 0.25;}

        if(sched.sched[16].time != "empty"){
        setSelectedIntervalSetFriAMH1([getTodayAtSpecificHour(parseInt(sched.sched[16].time.slice(4,6)),parseInt(sched.sched[16].time.slice(7,9))), getTodayAtSpecificHour(parseInt(sched.sched[17].time.slice(4,6)),parseInt(sched.sched[17].time.slice(7,9)))]);
        setSelectedIntervalSetFriPMH1([getTodayAtSpecificHour(parseInt(sched.sched[18].time.slice(4,6)),parseInt(sched.sched[18].time.slice(7,9))), getTodayAtSpecificHour(parseInt(sched.sched[19].time.slice(4,6)),parseInt(sched.sched[19].time.slice(7,9)))]);
        timestartAM = (getTodayAtSpecificHour(parseInt(sched.sched[16].time.slice(4,6)) ,parseInt(sched.sched[16].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        timeendAM = (getTodayAtSpecificHour(parseInt(sched.sched[17].time.slice(4,6)) ,parseInt(sched.sched[17].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        timestartPM = (getTodayAtSpecificHour(parseInt(sched.sched[18].time.slice(4,6)) ,parseInt(sched.sched[18].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        timeendPM = (getTodayAtSpecificHour(parseInt(sched.sched[19].time.slice(4,6)) ,parseInt(sched.sched[19].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        document.getElementById("showFriDay").innerHTML = timestartAM + " - " + timeendAM + " und " + timestartPM + " - " + timeendPM;
        }else{document.getElementById("showFriDay").parentElement.parentElement.parentElement.parentElement.style.opacity = 0.25;}


        

        //Haus2 setten 
        
        


        if(sched.sched[20].time != "empty"){
        setSelectedIntervalSetMonAMH2([getTodayAtSpecificHour(parseInt(sched.sched[20].time.slice(4,6)),parseInt(sched.sched[20].time.slice(7,9))), getTodayAtSpecificHour(parseInt(sched.sched[21].time.slice(4,6)),parseInt(sched.sched[21].time.slice(7,9)))]);
        setSelectedIntervalSetMonPMH2([getTodayAtSpecificHour(parseInt(sched.sched[22].time.slice(4,6)),parseInt(sched.sched[22].time.slice(7,9))), getTodayAtSpecificHour(parseInt(sched.sched[23].time.slice(4,6)),parseInt(sched.sched[23].time.slice(7,9)))]);
        var timestartAM = (getTodayAtSpecificHour(parseInt(sched.sched[20].time.slice(4,6)) ,parseInt(sched.sched[20].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        var timeendAM = (getTodayAtSpecificHour(parseInt(sched.sched[21].time.slice(4,6)) ,parseInt(sched.sched[21].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        var timestartPM = (getTodayAtSpecificHour(parseInt(sched.sched[22].time.slice(4,6)) ,parseInt(sched.sched[22].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        var timeendPM = (getTodayAtSpecificHour(parseInt(sched.sched[23].time.slice(4,6)) ,parseInt(sched.sched[23].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        document.getElementById("showMonDayH2").innerHTML = timestartAM + " - " + timeendAM + " und " + timestartPM + " - " + timeendPM;
        }else{document.getElementById("showMonDayH2").parentElement.parentElement.parentElement.parentElement.style.opacity = 0.25;}

        if(sched.sched[24].time != "empty"){
        setSelectedIntervalSetTueAMH2([getTodayAtSpecificHour(parseInt(sched.sched[24].time.slice(4,6)),parseInt(sched.sched[24].time.slice(7,9))), getTodayAtSpecificHour(parseInt(sched.sched[25].time.slice(4,6)),parseInt(sched.sched[25].time.slice(7,9)))]);
        setSelectedIntervalSetTuePMH2([getTodayAtSpecificHour(parseInt(sched.sched[26].time.slice(4,6)),parseInt(sched.sched[26].time.slice(7,9))), getTodayAtSpecificHour(parseInt(sched.sched[27].time.slice(4,6)),parseInt(sched.sched[27].time.slice(7,9)))]);
        var timestartAM = (getTodayAtSpecificHour(parseInt(sched.sched[24].time.slice(4,6)) ,parseInt(sched.sched[24].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        var timeendAM = (getTodayAtSpecificHour(parseInt(sched.sched[25].time.slice(4,6)) ,parseInt(sched.sched[25].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        var timestartPM = (getTodayAtSpecificHour(parseInt(sched.sched[26].time.slice(4,6)) ,parseInt(sched.sched[26].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        var timeendPM = (getTodayAtSpecificHour(parseInt(sched.sched[27].time.slice(4,6)) ,parseInt(sched.sched[27].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        document.getElementById("showTueDayH2").innerHTML = timestartAM + " - " + timeendAM + " und " + timestartPM + " - " + timeendPM;
        }else{document.getElementById("showTueDayH2").parentElement.parentElement.parentElement.parentElement.style.opacity = 0.25;}

        if(sched.sched[28].time != "empty"){
        setSelectedIntervalSetWedAMH2([getTodayAtSpecificHour(parseInt(sched.sched[28].time.slice(4,6)),parseInt(sched.sched[28].time.slice(7,9))), getTodayAtSpecificHour(parseInt(sched.sched[29].time.slice(4,6)),parseInt(sched.sched[29].time.slice(7,9)))]);
        setSelectedIntervalSetWedPMH2([getTodayAtSpecificHour(parseInt(sched.sched[30].time.slice(4,6)),parseInt(sched.sched[30].time.slice(7,9))), getTodayAtSpecificHour(parseInt(sched.sched[31].time.slice(4,6)),parseInt(sched.sched[31].time.slice(7,9)))]);
        var timestartAM = (getTodayAtSpecificHour(parseInt(sched.sched[28].time.slice(4,6)) ,parseInt(sched.sched[28].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        var timeendAM = (getTodayAtSpecificHour(parseInt(sched.sched[29].time.slice(4,6)) ,parseInt(sched.sched[29].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        var timestartPM = (getTodayAtSpecificHour(parseInt(sched.sched[30].time.slice(4,6)) ,parseInt(sched.sched[30].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        var timeendPM = (getTodayAtSpecificHour(parseInt(sched.sched[31].time.slice(4,6)) ,parseInt(sched.sched[31].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        document.getElementById("showWedDayH2").innerHTML = timestartAM + " - " + timeendAM + " und " + timestartPM + " - " + timeendPM;
        }else{document.getElementById("showWedDayH2").parentElement.parentElement.parentElement.parentElement.style.opacity = 0.25;}

        if(sched.sched[32].time != "empty"){
        setSelectedIntervalSetThuAMH2([getTodayAtSpecificHour(parseInt(sched.sched[32].time.slice(4,6)),parseInt(sched.sched[32].time.slice(7,9))), getTodayAtSpecificHour(parseInt(sched.sched[33].time.slice(4,6)),parseInt(sched.sched[33].time.slice(7,9)))]);
        setSelectedIntervalSetThuPMH2([getTodayAtSpecificHour(parseInt(sched.sched[34].time.slice(4,6)),parseInt(sched.sched[34].time.slice(7,9))), getTodayAtSpecificHour(parseInt(sched.sched[35].time.slice(4,6)),parseInt(sched.sched[35].time.slice(7,9)))]);
        var timestartAM = (getTodayAtSpecificHour(parseInt(sched.sched[32].time.slice(4,6)) ,parseInt(sched.sched[32].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        var timeendAM = (getTodayAtSpecificHour(parseInt(sched.sched[33].time.slice(4,6)) ,parseInt(sched.sched[33].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        var timestartPM = (getTodayAtSpecificHour(parseInt(sched.sched[34].time.slice(4,6)) ,parseInt(sched.sched[34].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        var timeendPM = (getTodayAtSpecificHour(parseInt(sched.sched[35].time.slice(4,6)) ,parseInt(sched.sched[35].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        document.getElementById("showThuDayH2").innerHTML = timestartAM + " - " + timeendAM + " und " + timestartPM + " - " + timeendPM;
        }else{document.getElementById("showThuDayH2").parentElement.parentElement.parentElement.parentElement.style.opacity = 0.25;}

        if(sched.sched[36].time != "empty"){
        setSelectedIntervalSetFriAMH2([getTodayAtSpecificHour(parseInt(sched.sched[36].time.slice(4,6)),parseInt(sched.sched[36].time.slice(7,9))), getTodayAtSpecificHour(parseInt(sched.sched[37].time.slice(4,6)),parseInt(sched.sched[37].time.slice(7,9)))]);
        setSelectedIntervalSetFriPMH2([getTodayAtSpecificHour(parseInt(sched.sched[38].time.slice(4,6)),parseInt(sched.sched[38].time.slice(7,9))), getTodayAtSpecificHour(parseInt(sched.sched[39].time.slice(4,6)),parseInt(sched.sched[39].time.slice(7,9)))]);
        var timestartAM = (getTodayAtSpecificHour(parseInt(sched.sched[36].time.slice(4,6)) ,parseInt(sched.sched[36].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        var timeendAM = (getTodayAtSpecificHour(parseInt(sched.sched[37].time.slice(4,6)) ,parseInt(sched.sched[37].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        var timestartPM = (getTodayAtSpecificHour(parseInt(sched.sched[38].time.slice(4,6)) ,parseInt(sched.sched[38].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        var timeendPM = (getTodayAtSpecificHour(parseInt(sched.sched[39].time.slice(4,6)) ,parseInt(sched.sched[39].time.slice(7,9)))).toLocaleTimeString("de-DE",{hour12: false, hour: '2-digit', minute:'2-digit'});
        document.getElementById("showFriDayH2").innerHTML = timestartAM + " - " + timeendAM + " und " + timestartPM + " - " + timeendPM;
        }else{document.getElementById("showFriDayH2").parentElement.parentElement.parentElement.parentElement.style.opacity = 0.25;}
    }


    }
        
    }

    const setAllBtn = document.getElementById('setAllBtn');
    setAllBtn.addEventListener('click', (event) => {
        if (event.target.click) {                
            setAllDays();
           }
    });
    
    const confButt = document.getElementById('confBtn');
    confButt.addEventListener('click', (event) => {
        if (event.target.click) {                
            newSched();
           }
    });

    document.getElementById("deleteSchedBtn").addEventListener("click", (e) => {
        if(e.target.click){
            setZeroSched();
        }
    })

    const cards = document.querySelectorAll('.dailyCards');

    cards.forEach(card => {
    card.addEventListener('click', () => {
        card.style.opacity = 1;
        });
    });
    
    
    function newSched() {
        var haus;
        var sched = [];
        
        var combinedConfig = ["showMonDay", "showTueDay", "showWedDay", "showThuDay", "showFriDay","showMonDayH2", "showTueDayH2", "showWedDayH2", "showThuDayH2", "showFriDayH2"]
        combinedConfig.forEach( function(inner){
            inner.slice(-1)=="2"?haus = "H2":haus = "H1";

            var timeDay = inner.substring(4,7);
            var timeOn = document.getElementById(inner).innerHTML.substring(0,5);
            var timeOff = document.getElementById(inner).innerHTML.substring(8,13);
            var timeOnPM = document.getElementById(inner).innerHTML.substring(18,23);
            var timeOffPM = document.getElementById(inner).innerHTML.substring(26,31);
            var timeList = [timeOn, timeOff, timeOnPM, timeOffPM]
            
            var parent = document.getElementById(inner).parentElement.parentElement.parentElement.parentElement

            console.log(parent)
            console.log(parent.style.opacity);
            if (parent.style.opacity == "0.25" || document.getElementById(inner).innerHTML == ""){
                for(var x = 0; x < 4; x++){
                    var emptyInstance = {
                        "time" : "empty",
                        "h0"  : "1"
                    }
                    sched.push(emptyInstance);
                }
                return;
                
            }
            for ( var x = 0; x < timeList.length; x++){
                
                let timeParts = timeList[x].split(":");
                let date = new Date();

                date.setHours(parseInt(timeParts[0]));
                date.setMinutes(parseInt(timeParts[1]));
                date.setHours(date.getHours());

                let newHours = date.getHours();
                let newMinutes = date.getMinutes(); 

                // Führende Nullen hinzufügen, falls notwendig
                if (newHours < 10) {
                    newHours = "0" + newHours;
                }
                if (newMinutes < 10) {
                    newMinutes = "0" + newMinutes;
                }

                let newTimeString = newHours + ":" + newMinutes;

                timeList[x] = newTimeString;
            }



            var confInstanceOnAM = {
                "time" : timeDay+"-"+timeList[0],
                [haus]  : "1"
            }
            var confInstanceOffAM = {
                "time" : timeDay+"-"+timeList[1],
                [haus]  : "0"
            }
            var confInstanceOnPM = {
                "time" : timeDay+"-"+timeList[2],
                [haus]  : "1"
            }
            var confInstanceOffPM = {
                "time" : timeDay+"-"+timeList[3],
                [haus]  : "0"
            }

            sched.push(confInstanceOnAM);
            sched.push(confInstanceOffAM);
            sched.push(confInstanceOnPM);
            sched.push(confInstanceOffPM);
        })
        console.log("created new schedule");
        console.log(sched)
        var Jayson = {
            sched
        }
        console.log(Jayson);

        publishSetSched(Jayson);
    }


    function setZeroSched(){
        console.log("sending empty json");
        var sched = [];
        var JaysonEmpty = {
            sched
        }
        console.log(JaysonEmpty);
        publishSetSched(JaysonEmpty); 

        
    }

    function setAllDays() {
        var hausA
        var sched = [];

        var AllAmOn = document.getElementById("showAllDayAM").innerHTML.substring(0,5);
        var AllAmOff = document.getElementById("showAllDayAM").innerHTML.substring(8,13);
        var AllPmOn = document.getElementById("showAllDayPM").innerHTML.substring(0,5);
        var AllPmOff = document.getElementById("showAllDayPM").innerHTML.substring(8,13);
        
        var timeList = [AllAmOn, AllAmOff, AllPmOn, AllPmOff]
            for ( var x = 0; x < timeList.length; x++){
                
                let timeParts = timeList[x].split(":");
                let date = new Date();

                date.setHours(parseInt(timeParts[0]));
                date.setMinutes(parseInt(timeParts[1]));
                date.setHours(date.getHours());

                let newHours = date.getHours();
                let newMinutes = date.getMinutes(); 

                // Führende Nullen hinzufügen, falls notwendig
                if (newHours < 10) {
                    newHours = "0" + newHours;
                }
                if (newMinutes < 10) {
                    newMinutes = "0" + newMinutes;
                }

                let newTimeString = newHours + ":" + newMinutes;

                timeList[x] = newTimeString;
            }


        var combinedConfig = ["showMonDay", "showTueDay", "showWedDay", "showThuDay", "showFriDay","showMonDayH2", "showTueDayH2", "showWedDayH2", "showThuDayH2", "showFriDayH2"]



        combinedConfig.forEach( function(inner){
            inner.slice(-1)=="2"?hausA = "H2":hausA = "H1";

            var timeDay = inner.substring(4,7);
            var confInstanceOnAM = {
                "time" : timeDay+"-"+timeList[0],
                [hausA]  : "1"
                
            }
            var confInstanceOffAM = {
                "time" : timeDay+"-"+timeList[1],
                [hausA]  : "0"
                
            }
            var confInstanceOnPM = {
                "time" : timeDay+"-"+timeList[2],
                [hausA]  : "1"
                
            }
            var confInstanceOffPM = {
                "time" : timeDay+"-"+timeList[3],
                [hausA]  : "0"
               
            }

            sched.push(confInstanceOnAM);
            sched.push(confInstanceOffAM);
            sched.push(confInstanceOnPM);
            sched.push(confInstanceOffPM);
        })
        var JaysonAll = {
            sched
        }
        console.log(JaysonAll);
        publishSetSched(JaysonAll); 
    }



    client.on('close', function () {            
        console.log("Außenbeleuchtung DISCONNECTED")
    })

    return () => client.end(); // <-- kill on unmount
    }, []) // <-- empty dependency array

    return(
    <div className="distancer">
        {/* // SUBNAVIGATION */}
        <div className="subnav">
            <div className="fltl">
                <Link to="/Devices">
                    <Button variant="contained" color="primary" className="back-button" startIcon={<ArrowBackIosTwoToneIcon/>}>Geräte</Button>
                </Link>
                </div>
            <div className="fltr">
                <Button id="lightsOnline" variant="outlined" color="primary" className="online fltr hide" disableElevation disabled startIcon={<LinkIcon/>}>Verbunden</Button>
                <Button id="lightsOffline" variant="outlined" color="primary" className="offline fltr" disableElevation disabled startIcon={<LinkOffIcon/>}>Verbindung</Button>
            </div>
            <div className="spacer"></div>                     
        </div>
        {/* END SUBNAVIGATION*/}    
        <div className="outer-title distancer-bottom">
            Außenbeleuchtung
        </div>            
        <Row>
            <Col md="12" lg="6">
                <Card className="deviceCard">
                    <Card.Header className="lightsHeader">                 
                        <div className='card-icon'><Icon name="bulb-eco"/></div>
                        <div className="card-title">Haus 1</div>
                        <div className="cardGroupSwitch">
                            <label className="switch">
                                <input id="group-1" type="checkbox" className="lightSwitchGroup"/>
                                <span className="slider"></span>
                            </label>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div className="groupLightswitch">
                            <div className="cardLabel">
                                <span className="">Osten / Südost</span>
                            </div>
                            <div className="cardGroupSwitch">
                                <label className="switch">
                                    <input id="light-1-1" type="checkbox"/>
                                    <span className="slider"></span>
                                </label>                              
                            </div>
                        </div>
                        <div className="groupLightswitch">
                            <div className="cardLabel">
                                <span className="cardLabel">Norden / Nordwest</span>
                            </div>
                            <div className="cardGroupSwitch">
                                <label className="switch">
                                    <input id="light-1-2" type="checkbox"/>
                                    <span className="slider"></span>
                                </label>
                            </div>
                        </div>
                        <div className="groupLightswitch">
                            <div className="cardLabel">
                                <span className="cardLabel">Süden</span>
                            </div>
                            <div className="cardGroupSwitch">
                                <label className="switch">
                                    <input id="light-1-3" type="checkbox"/>
                                    <span className="slider"></span>
                                </label>
                            </div>	
                        </div>
                        <div className="groupLightswitch groupLightswitch14">
                            <div className="cardLabel">
                                <span className="cardLabel">Süden</span>
                            </div>
                            <div className="cardGroupSwitch">
                                <label className="switch">
                                    <input id="light-1-4" type="checkbox"/>
                                    <span className="slider"></span>
                                </label>
                            </div>	
                        </div>                                   
                    </Card.Body>
                </Card>
            </Col>
            <Col md="12" lg="6">
                <Card className="deviceCard">
                    <Card.Header className="lightsHeader">
                        <div className='card-icon'><Icon name="bulb-eco"/></div>                      
                        <div className="card-title">Haus 2</div>
                        <div className="cardGroupSwitch">
                            <label className="switch">
                                    <input id="group-2" type="checkbox"  className="lightSwitchGroup"/>
                                    <span className="slider"></span>
                            </label>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div className="groupLightswitch">
                            <div className="cardLabel">
                                <span className="">Norden</span>
                            </div>
                            <div className="cardGroupSwitch">
                                <label className="switch">
                                    <input id="light-2-1" type="checkbox"/>
                                    <span className="slider"></span>
                                </label>
                            </div>
                        </div>
                        <div className="groupLightswitch groupLightswitch22">
                            <div className="cardLabel">
                                <span className="">Norden</span>
                            </div>
                            <div className="cardGroupSwitch">
                                <label className="switch">
                                    <input id="light-2-2" type="checkbox"/>
                                    <span className="slider"></span>
                                </label>
                            </div>
                        </div>                       
                        <div className="groupLightswitch">
                            <div className="cardLabel">
                                <span className="cardLabel">Westen</span>
                            </div>
                            <div className="cardGroupSwitch">
                                <label className="switch">
                                    <input id="light-2-3" type="checkbox"/>
                                    <span className="slider"></span>
                                </label>
                            </div>
                        </div>
                        <div className="groupLightswitch">
                            <div className="cardLabel">
                                <span className="cardLabel">Osten / Süden</span>
                            </div>
                            <div className="cardGroupSwitch">
                                <label className="switch">
                                    <input id="light-2-4" type="checkbox"/>
                                    <span className="slider"></span>
                                </label>
                            </div>	
                        </div>              
                    </Card.Body>
                </Card>
            </Col>               
        </Row>
        <br/>
        <Row>
            <Col md="12" lg="12">
                <Card>
                    <Card.Header>
                        Zeit für alle Tage setzen
                    </Card.Header>
                    <Card.Body>
                        <div className='lightsSchedSet'>
                            <b>Morgens </b>
                            <b id='showAllDayAM'></b>
                            
                            
                            <TimeRange classList="SliderAMAll"
                                error={error}
                                ticksNumber={18}
                                selectedInterval={selectedIntervalSetAllAM}
                                timelineInterval={[startTimeAM, endTimeAM]}
                                onUpdateCallback={errorHandler}
                                onChangeCallback={onChangeCallbackAllAM}
                                disabledIntervals={disabledIntervals}
                                step={900000}
                                
                                />   
                            
                            {/*                 
                            AN <input type="number" defaultValue={allAON1} max="23" min="0"  id="allAON1"/>:<input type="number" defaultValue={allAON2} max="59" min="0" id="allAON2" /> &nbsp;&nbsp;
                            AUS <input type="number" defaultValue={allAOFF1} max="23" min="0"  id="allAOFF1"/>:<input type="number" defaultValue={allAOFF2} max="59" min="0"  id="allAOFF2"/>
                            */}
                            <hr></hr>
                            
                            <b>Abends </b>
                            <b id='showAllDayPM'></b>

                            <TimeRange classList="SliderPMAll"
                                error={error}
                                ticksNumber={18}
                                selectedInterval={selectedIntervalSetAllPM}
                                timelineInterval={[startTimePM, endTimePM]}
                                onUpdateCallback={errorHandler}
                                onChangeCallback={onChangeCallbackAllPM}
                                disabledIntervals={disabledIntervals}
                                step={900000}
                                /> 
                             
                            {/*
                            AN <input type="number" defaultValue={allBON1} max="23" min="0"  id="allBON1" />:<input type="number" defaultValue={allBON2} max="59" min="0"  id="allBON2"/> &nbsp;&nbsp;
                            AUS <input type="number" defaultValue={allBOFF1} max="23" min="0"  id="allBOFF1"/>:<input type="number" defaultValue={allBOFF2} max="59" min="0"  id="allBOFF2"/>
                            */}
                        </div>
                        <br/><br/><br/>
                            <div className="btnWPWrapper" style={{ display : "flex", justifyContent: "space-around"}}>
                                <Button id="setAllBtn" variant="contained" color="primary" className="btnWithProgress">Alle Tage Setzen</Button>
                                <Button id="deleteSchedBtn" variant="contained" className="btnWithProgress">Alle Tage Deaktivieren</Button>
                            </div>
                    </Card.Body>
                </Card>
                <br/>
                <Card className="lageplan-card">
                    <Card.Body>
                        <Tabs id="uncontrolled-tab-example" defaultActiveKey="house1">
                            <Tab eventKey="house1" title="Haus 1">
                            <div className="lageplan-tab">
                                <div style={{ opacity: disableMonH1? 0.25:1 }}>                                    
                                    <div className='sliderHead' style={{display: "flex", justifyContent: "space-between"}}>
                                        <div>
                                            <b>H1 Montag </b>
                                            <b id='showMonDay'></b>
                                        </div>
                                        <div>
                                            <Button variant='outlined' color="primary" onClick={() => {setDisableMonH1(prev => !prev)}}>X</Button>
                                        </div>                                    
                                    </div>
                                    <TimeRange classList="SliderAMAll"
                                    error={error}
                                    ticksNumber={18}
                                    selectedInterval={selectedIntervalSetMonAMH1}
                                    timelineInterval={[startTimeAM, endTimeAM]}
                                    onUpdateCallback={errorHandler}
                                    onChangeCallback={onChangeCallbackMonAMH1}
                                    disabledIntervals={disabledIntervals}
                                    step={900000}                                    
                                    />
                                    <br/><br/>
                                    <TimeRange classList="SliderPMAll"
                                        error={error}
                                        ticksNumber={18}
                                        selectedInterval={selectedIntervalSetMonPMH1}
                                        timelineInterval={[startTimePM, endTimePM]}
                                        onUpdateCallback={errorHandler}
                                        onChangeCallback={onChangeCallbackMonPMH1}
                                        disabledIntervals={disabledIntervals}
                                        step={900000}
                                    />
                                </div>
                                <br></br>
                                <hr></hr>
                                <div className='dailyCards' style={{ opacity: disableTueH1? 0.25:1 }}>                                    
                                    <div className='sliderHead' style={{display: "flex", justifyContent: "space-between"}}>
                                        <div>
                                            <b>H1 Dienstag </b>
                                            <b id='showTueDay'></b>
                                        </div>
                                        <div>
                                            <Button variant='outlined' color="primary" onClick={() => {setDisableTueH1(prev => !prev)}}>X</Button>
                                        </div>                                        
                                    </div>
                                        <TimeRange
                                        error={error}
                                        ticksNumber={18}
                                        selectedInterval={selectedIntervalSetTueAMH1}
                                        timelineInterval={[startTimeAM, endTimeAM]}
                                        onUpdateCallback={errorHandler}
                                        onChangeCallback={onChangeCallbackTueAMH1}
                                        disabledIntervals={disabledIntervals}
                                        step={900000}                                        
                                        />
                                        <br/><br/>
                                    <TimeRange
                                        error={error}
                                        ticksNumber={18}
                                        selectedInterval={selectedIntervalSetTuePMH1}
                                        timelineInterval={[startTimePM, endTimePM]}
                                        onUpdateCallback={errorHandler}
                                        onChangeCallback={onChangeCallbackTuePMH1}
                                        disabledIntervals={disabledIntervals}
                                        step={900000}
                                        /> 
                                </div>
                                <br></br>
                                <hr></hr>
                                {/*CARD MITTWOCH */}
                                <div className='dailyCards' style={{ opacity: disableWedH1? 0.25:1 }}>
                                    <div className='sliderHead' style={{display: "flex", justifyContent: "space-between"}}>
                                        <div>
                                            <b>H1 Mittwoch </b>
                                            <b id='showWedDay'></b>
                                        </div>
                                        <div>
                                            <Button variant='outlined' color="primary" onClick={() => {setDisableWedH1(prev => !prev)}}>X</Button>
                                        </div>                                        
                                    </div>
                                        <TimeRange
                                        error={error}
                                        ticksNumber={18}
                                        selectedInterval={selectedIntervalSetWedAMH1}
                                        timelineInterval={[startTimeAM, endTimeAM]}
                                        onUpdateCallback={errorHandler}
                                        onChangeCallback={onChangeCallbackWedAMH1}
                                        disabledIntervals={disabledIntervals}
                                        step={900000}
                                        
                                        />
                                        <br/><br/>
                                    <TimeRange
                                        error={error}
                                        ticksNumber={18}
                                        selectedInterval={selectedIntervalSetWedPMH1}
                                        timelineInterval={[startTimePM, endTimePM]}
                                        onUpdateCallback={errorHandler}
                                        onChangeCallback={onChangeCallbackWedPMH1}
                                        disabledIntervals={disabledIntervals}
                                        step={900000}
                                        />                                                                     
                                </div>
                                <br></br>
                                <hr></hr>
                                {/*CARD DONNERSTAG*/}
                                <div className='dailyCards' style={{ opacity: disableThuH1? 0.25:1 }}>                                    
                                    <div className='sliderHead' style={{display: "flex", justifyContent: "space-between"}}>
                                        <div>
                                            <b>H1 Donnerstag </b>
                                            <b id='showThuDay'></b>
                                        </div>
                                        <div>
                                            <Button variant='outlined' color="primary" onClick={() => {setDisableThuH1(prev => !prev)}}>X</Button>
                                        </div>                                        
                                    </div>
                                        <TimeRange
                                        error={error}
                                        ticksNumber={18}
                                        selectedInterval={selectedIntervalSetThuAMH1}
                                        timelineInterval={[startTimeAM, endTimeAM]}
                                        onUpdateCallback={errorHandler}
                                        onChangeCallback={onChangeCallbackThuAMH1}
                                        disabledIntervals={disabledIntervals}
                                        step={900000}
                                        
                                        />
                                        <br/><br/>
                                    <TimeRange
                                        error={error}
                                        ticksNumber={18}
                                        selectedInterval={selectedIntervalSetThuPMH1}
                                        timelineInterval={[startTimePM, endTimePM]}
                                        onUpdateCallback={errorHandler}
                                        onChangeCallback={onChangeCallbackThuPMH1}
                                        disabledIntervals={disabledIntervals}
                                        step={900000}
                                        />
                                </div>
                                <br></br>
                                <hr></hr>
                                {/*CARD FREITAG*/}
                                <div className='dailyCards' style={{ opacity: disableFriH1? 0.25:1 }}>                           
                                    <div className='sliderHead' style={{display: "flex", justifyContent: "space-between"}}>
                                        <div>
                                            <b>H1 Freitag </b>
                                            <b id='showFriDay'></b>
                                        </div>
                                        <div>
                                            <Button variant='outlined' color="primary" onClick={() => {setDisableFriH1(prev => !prev)}}>X</Button>
                                        </div>                                    
                                    </div>
                                    <TimeRange
                                    error={error}
                                    ticksNumber={18}
                                    selectedInterval={selectedIntervalSetFriAMH1}
                                    timelineInterval={[startTimeAM, endTimeAM]}
                                    onUpdateCallback={errorHandler}
                                    onChangeCallback={onChangeCallbackFriAMH1}
                                    disabledIntervals={disabledIntervals}
                                    step={900000}
                                    
                                    />
                                    <br/><br/>
                                    <TimeRange
                                    error={error}
                                    ticksNumber={18}
                                    selectedInterval={selectedIntervalSetFriPMH1}
                                    timelineInterval={[startTimePM, endTimePM]}
                                    onUpdateCallback={errorHandler}
                                    onChangeCallback={onChangeCallbackFriPMH1}
                                    disabledIntervals={disabledIntervals}
                                    step={900000}
                                    />
                                </div>
                            </div>                            
                            </Tab>
                            {/*HAUS 2 #####################################################################################################################*/}
                            <Tab eventKey="house2" title="Haus 2">
                                <div className="lageplan-tab">
                                    <div className='dailyCards' style={{ opacity: disableMonH2? 0.25:1 }}>                                    
                                        <div className='sliderHead' style={{display: "flex", justifyContent: "space-between"}}>
                                            <div>
                                                <b>H2 Montag </b>
                                                <b id='showMonDayH2'></b>
                                            </div>
                                            <div>
                                                <Button variant='outlined' color="primary" onClick={() => {setDisableMonH2(prev => !prev)}}>X</Button>
                                            </div>                                        
                                        </div>                                    
                                        <TimeRange classList="SliderAMAll"
                                        error={error}
                                        ticksNumber={18}
                                        selectedInterval={selectedIntervalSetMonAMH2}
                                        timelineInterval={[startTimeAM, endTimeAM]}
                                        onUpdateCallback={errorHandler}
                                        onChangeCallback={onChangeCallbackMonAMH2}
                                        disabledIntervals={disabledIntervals}
                                        step={900000}                                        
                                        />
                                        <br/><br/>
                                        <TimeRange classList="SliderPMAll"
                                        error={error}
                                        ticksNumber={18}
                                        selectedInterval={selectedIntervalSetMonPMH2}
                                        timelineInterval={[startTimePM, endTimePM]}
                                        onUpdateCallback={errorHandler}
                                        onChangeCallback={onChangeCallbackMonPMH2}
                                        disabledIntervals={disabledIntervals}
                                        step={900000}
                                        />                                    
                                    </div>
                                <br></br>
                                <hr></hr>
                                {/*CARD DIENSTAG */}
                                <div className='dailyCards' style={{ opacity: disableTueH2? 0.25:1 }}>
                                    
                                        <div className='sliderHead' style={{display: "flex", justifyContent: "space-between"}}>
                                        <div>
                                            <b>H2 Dienstag </b>
                                            <b id='showTueDayH2'></b>
                                        </div>
                                        <div>
                                            <Button variant='outlined' color="primary" onClick={() => {setDisableTueH2(prev => !prev)}}>X</Button>
                                        </div>
                                        
                                    </div>

                                        <TimeRange
                                        error={error}
                                        ticksNumber={18}
                                        selectedInterval={selectedIntervalSetTueAMH2}
                                        timelineInterval={[startTimeAM, endTimeAM]}
                                        onUpdateCallback={errorHandler}
                                        onChangeCallback={onChangeCallbackTueAMH2}
                                        disabledIntervals={disabledIntervals}
                                        step={900000}
                                        
                                        />
                                        <br/><br/>
                                    <TimeRange
                                        error={error}
                                        ticksNumber={18}
                                        selectedInterval={selectedIntervalSetTuePMH2}
                                        timelineInterval={[startTimePM, endTimePM]}
                                        onUpdateCallback={errorHandler}
                                        onChangeCallback={onChangeCallbackTuePMH2}
                                        disabledIntervals={disabledIntervals}
                                        step={900000}
                                        />
                                    
                                    
                                </div>
                                <br></br>
                                <hr></hr>
                                {/*CARD MITTWOCH */}
                                <div className='dailyCards' style={{ opacity: disableWedH2? 0.25:1 }}>                                    
                                    <div className='sliderHead' style={{display: "flex", justifyContent: "space-between"}}>
                                        <div>
                                            <b>H2 Mittwoch </b>
                                            <b id='showWedDayH2'></b>
                                        </div>
                                        <div>
                                            <Button variant='outlined' color="primary" onClick={() => {setDisableWedH2(prev => !prev)}}>X</Button>
                                        </div>                                        
                                    </div>
                                        <TimeRange
                                        error={error}
                                        ticksNumber={18}
                                        selectedInterval={selectedIntervalSetWedAMH2}
                                        timelineInterval={[startTimeAM, endTimeAM]}
                                        onUpdateCallback={errorHandler}
                                        onChangeCallback={onChangeCallbackWedAMH2}
                                        disabledIntervals={disabledIntervals}
                                        step={900000}                                        
                                        />
                                        <br/><br/>
                                    <TimeRange
                                        error={error}
                                        ticksNumber={18}
                                        selectedInterval={selectedIntervalSetWedPMH2}
                                        timelineInterval={[startTimePM, endTimePM]}
                                        onUpdateCallback={errorHandler}
                                        onChangeCallback={onChangeCallbackWedPMH2}
                                        disabledIntervals={disabledIntervals}
                                        step={900000}
                                        />  
                                </div>
                                <br></br>
                                <hr></hr>
                                {/*CARD DONNERSTAG*/}
                                <div className='dailyCards' style={{ opacity: disableThuH2? 0.25:1 }}>
                                        <div className='sliderHead' style={{display: "flex", justifyContent: "space-between"}}>
                                        <div>
                                            <b>H2 Donnerstag </b>
                                            <b id='showThuDayH2'></b>
                                        </div>
                                        <div>
                                            <Button variant='outlined' color="primary" onClick={() => {setDisableThuH2(prev => !prev)}}>X</Button>
                                        </div>                                        
                                    </div>
                                        <TimeRange
                                        error={error}
                                        ticksNumber={18}
                                        selectedInterval={selectedIntervalSetThuAMH2}
                                        timelineInterval={[startTimeAM, endTimeAM]}
                                        onUpdateCallback={errorHandler}
                                        onChangeCallback={onChangeCallbackThuAMH2}
                                        disabledIntervals={disabledIntervals}
                                        step={900000}
                                        
                                        />
                                    <br/><br/>
                                    <TimeRange
                                        error={error}
                                        ticksNumber={18}
                                        selectedInterval={selectedIntervalSetThuPMH2}
                                        timelineInterval={[startTimePM, endTimePM]}
                                        onUpdateCallback={errorHandler}
                                        onChangeCallback={onChangeCallbackThuPMH2}
                                        disabledIntervals={disabledIntervals}
                                        step={900000}
                                        />                                    
                                    </div>
                                    <br></br>
                                    <hr></hr>
                                    {/*CARD FREITAG*/}
                                    <div className='dailyCards' style={{ opacity: disableFriH2? 0.25:1 }}>
                                        
                                            <div className='sliderHead' style={{display: "flex", justifyContent: "space-between"}}>
                                            <div>
                                                <b>H2 Freitag </b>
                                                <b id='showFriDayH2'></b>
                                            </div>
                                            <div>
                                                <Button variant='outlined' color="primary" onClick={() => {setDisableFriH2(prev => !prev)}}>X</Button>
                                            </div>                                            
                                        </div>
                                            <TimeRange
                                            error={error}
                                            ticksNumber={18}
                                            selectedInterval={selectedIntervalSetFriAMH2}
                                            timelineInterval={[startTimeAM, endTimeAM]}
                                            onUpdateCallback={errorHandler}
                                            onChangeCallback={onChangeCallbackFriAMH2}
                                            disabledIntervals={disabledIntervals}
                                            step={900000}                                            
                                            />
                                            <br/><br/>
                                        <TimeRange
                                            error={error}
                                            ticksNumber={18}
                                            selectedInterval={selectedIntervalSetFriPMH2}
                                            timelineInterval={[startTimePM, endTimePM]}
                                            onUpdateCallback={errorHandler}
                                            onChangeCallback={onChangeCallbackFriPMH2}
                                            disabledIntervals={disabledIntervals}
                                            step={900000}
                                            />                                        
                                    </div>
                                </div>                                
                            </Tab>
                        </Tabs>        
                        <br></br>
                        <Button id="confBtn" variant="contained" color="primary" className="btnWithProgress">Speichern</Button>                
                    </Card.Body>            
                </Card>
            </Col>
        </Row>
        <br/><br/>
    </div> 
    )
}
export default Lights;