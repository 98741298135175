import React, {Component, useState, Fragment, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ArrowBackIosTwoToneIcon from '@material-ui/icons/ArrowBackIosTwoTone';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';

import '../../../assets/css/trennstation.scss';
import DTbackgroundImage from '../../../assets/images/modules/trennstation/dehoust_display_trennstation.png';
import DehoustD from '../../../assets/dehoust_2.png';

function Trennstation() {    
    const [deviceStatus, setDeviceStatus] = useState("Offline");
    const [firmware, setFirmware] = useState("");
    const [fillingLevel, setFillingLevelTank] = useState();
    const [waterInlineStyle, setWaterInlineStyle] = useState();
    const [automode, setAutomode] = useState();
    const [druckausgang, setDruckausgang] = useState();    
    const [notstopp, setNotstopp] = useState();

    const [RunetimeP1, setRunetimeP1] = useState();
    const [RunetimeP2, setRunetimeP2] = useState();
    const [RunetimeP1max, setRunetimeP1max] = useState();
    const [RunetimeP2max, setRunetimeP2max] = useState();   
    const [RunetimeNSP1, setRunetimeNSP1] = useState();
    const [RunetimeNSP2, setRunetimeNSP2] = useState();  
    
    const [pumpe1, setPumpe1] = useState();
    const [pumpe2, setPumpe2] = useState();
    const [nsp, setNSP] = useState();
    const [autodrainvent, setAutodrainvent] = useState();

    const [p1Leistung, setP1Leistung] = useState();
    const [p2Leistung, setP2Leistung] = useState();

    

    useEffect(()=>{        

        // do stuff here...
        var mqtt = require('mqtt')
        var Broker = "mqtts://mqtt.consoft.de:8001";
        var options = {
            protocol: 'mqtts',
            clientId: 'EZGWebsiban' + Math.floor(Math.random() * 1000)
        };

        var Topic1 = "Dehoust/6942B8DD-0511-41AF-A960-D5960573BA0D/status";   
        var Topic2 = "Dehoust/6942B8DD-0511-41AF-A960-D5960573BA0D/OUT/#";
        

        var client  = mqtt.connect(Broker, options);

        client.on('connect', function () {
            var trennstationOfflineIndicator = document.getElementById("trennstationOffline");
            var trennstationOnlineIndicator = document.getElementById("trennstationOnline");            

            if (trennstationOnlineIndicator !=  null) {
                trennstationOfflineIndicator.classList.add('hide');   
                trennstationOnlineIndicator.classList.remove('hide'); 
            }         

            console.log('Trennstation CONNECTED as ' + options.clientId);   

            client.subscribe(Topic1, function (err) {;
                if (!err) {
                console.log('SUBBED TOPIC STATUS');                             
                }
            })
            client.subscribe(Topic2, function (err) {;
                if (!err) {
                console.log('SUBBED TOPIC OUT');
                //publish("GETALL", "Dehoust/" + currentTopic + "/IN", 1, false);
                client.publish('Dehoust/6942B8DD-0511-41AF-A960-D5960573BA0D/IN', "GETALL");
                }
            })   
        })

        client.on('message', function (topic, message) {
        // message is Buffer  
        console.log("onMessageArrived Message:" + message);
        console.log("onMessageArrived Topic:" + topic);

            // STATUS
            if (topic.endsWith("status")) {
                setDeviceStatus((message.toString()))
            }
            // FIRMWARE
            if (topic.endsWith("FWVersion")) {
                    setFirmware((message.toString()))
            }
            // FÜLLSTAND
            if (topic.toLocaleUpperCase().endsWith("TANK")) {
                var levelInt = parseInt(barray2Value(message));

                if (levelInt > 90) {
                    levelInt = 90;
                }

                setWaterInlineStyle({height: levelInt + "%"})
                setFillingLevelTank((levelInt))
            }
            // AUTOMODE
            if (topic.toLocaleUpperCase().endsWith("AUTOMODE")) {
                var autoModeInt = parseInt(barray2Value(message));
                var autoModeStr;
                // 0 = Automatik, 1 = Automatik mit Trinkwasser, 2 = Automatik mit Regenwasser
                if (autoModeInt === 0) {
                    autoModeStr = "Automatik"
                }
                if (autoModeInt === 1) {
                    autoModeStr = "Automatik mit Trinkwasser"
                }
                if (autoModeInt === 2) {
                    autoModeStr = "Automatik mit Regenwasser"
                }              
                setAutomode((autoModeStr))
            }      
            // DRUCKAUSGANG
            if (topic.toLocaleUpperCase().endsWith("DRUCKAUSGANG")) {
                var duckausgangInt = parseFloat(barray2Value(message)) / 10;
                setDruckausgang((duckausgangInt.toString()))
            }       
            // PUMPE1
            if (topic.toLocaleUpperCase().endsWith("PUMPE1")) {
                var pumpe1Int = parseFloat(barray2Value(message));
                setPumpe1(pumpe1Int)

                var pump1Active = document.getElementById("pump1On");
                var pump1Inactive = document.getElementById("pump1Off");

                if (pumpe1Int === 1) {
                    pump1Active.classList.remove('hide');
                    pump1Inactive.classList.add('hide');
                }
                else {
                    pump1Active.classList.add('hide');
                    pump1Inactive.classList.remove('hide');                    
                }
            }

            // PUMPE2
            if (topic.toLocaleUpperCase().endsWith("PUMPE2")) {
                var pumpe2Int = parseFloat(barray2Value(message));
                setPumpe2(pumpe2Int)

                var pump2Active = document.getElementById("pump2On");
                var pump2Inactive = document.getElementById("pump2Off");

                if (pumpe2Int === 1) {
                    pump2Active.classList.remove('hide');
                    pump2Inactive.classList.add('hide');
                }
                else {
                    pump2Active.classList.add('hide');
                    pump2Inactive.classList.remove('hide');                    
                }
            }  

            // if (topic.toLocaleUpperCase().endsWith("P1LEISTUNG")) {
            //     var p1LeistungInt = parseFloat(barray2Value(message));
            //     var p1Leistung = Math.floor((p1LeistungInt / 4096) * 100);
            //     setP1Leistung(p1Leistung)                

            //     var p1LeistungIcon = document.getElementById("pump1FreqIco");
            //     p1LeistungIcon.classList.remove('hide');
            // }

            // if (topic.toLocaleUpperCase().endsWith("P2LEISTUNG")) {
            //     var p2LeistungInt = parseFloat(barray2Value(message));
            //     var p2Leistung = Math.floor((p2LeistungInt / 4096) * 100);
            //     setP2Leistung(p2Leistung)                

            //     var p2LeistungIcon = document.getElementById("pump2FreqIco");
            //     p2LeistungIcon.classList.remove('hide');
            // }

            // NSP
            if (topic.toLocaleUpperCase().endsWith("NSP")) {
                var nspInt = parseFloat(barray2Value(message));                
                setNSP(nspInt)

                var nspActive = document.getElementById("nspAuf");
                var nspInactive = document.getElementById("nspZu");

                if (nspInt === 1) {
                    nspActive.classList.remove('hide');
                    nspInactive.classList.add('hide');
                }
                else {
                    nspActive.classList.add('hide');
                    nspInactive.classList.remove('hide');                    
                }

            }

            //AUTODRAIN 
            if (topic.toLocaleUpperCase().endsWith("AUTODRAIN")) {                
                var autoDrainInt = parseInt(barray2Value(message));
                if (autoDrainInt === 1) {
                    var autoDrainEle = document.getElementById("autodrain");
                    autoDrainEle.classList.remove('hide');
                    var lineEle = document.getElementById("line");
                    lineEle.classList.remove('hide');
                    var autodrainLabelEle = document.getElementById("autodrainLabel");
                    autodrainLabelEle.classList.remove('hide');
                }
            }
            
            // AUTODRAINVENT
            if (topic.toLocaleUpperCase().endsWith("AUTODRAINVENT")) {
                var autodrainventInt = parseFloat(barray2Value(message));                
                setAutodrainvent(autodrainventInt)

                var autodrainActive = document.getElementById("autodrainAuf");
                var autodrainInactive = document.getElementById("autodrainZu");

                if (autodrainventInt === 1) {
                    autodrainActive.classList.remove('hide');
                    autodrainInactive.classList.add('hide');
                }
                else {
                    autodrainActive.classList.add('hide');
                    autodrainInactive.classList.remove('hide');                    
                }
            }

            //NOTSTOPP           
            if (topic.toLocaleUpperCase().endsWith("NOTSTOPP")) {
                var notstoppInt = (barray2Value(message));
                var notstoppStr;
                var notstoppDiv = document.getElementById("notstoppDiv");

                if (notstoppInt === 1) {
                    notstoppStr = "Alle Ausgänge gesperrt!"
                    notstoppDiv.classList.remove('hide');
                }
                else {
                    notstoppDiv.classList.add('hide');
                }
                setNotstopp((notstoppStr));                         
            }
            //STATS
            if (topic.toLocaleUpperCase().endsWith("STATS")) {

                var statStr = message.toString();
                var statArray = statStr.split(':'); 
                
                var runtimep1hours = parseInt(statArray[0]);
                var runtimep1minutesArray = (statArray[0] + "").split(".");
                var runtimep1minutes = runtimep1minutesArray[1] / 10;
                runtimep1minutes = 60 * runtimep1minutes;
                var runtimep1minutesFormat;
                if (runtimep1minutes.toString().length === 1) {
                    runtimep1minutesFormat = '0' + runtimep1minutes.toString();
                }
                else {
                    runtimep1minutesFormat = runtimep1minutes;
                }
                
                var runtimep1hoursmax = parseInt(statArray[1]);
                var runtimep1minutesArraymax = (statArray[1] + "").split(".");
                var runtimep1minutesmax = runtimep1minutesArraymax[1] / 10;
                runtimep1minutesmax = 60 * runtimep1minutesmax;
                var runtimep1minutesmaxFormat;
                if (runtimep1minutesmax.toString().length === 1) {
                    runtimep1minutesmaxFormat = '0' + runtimep1minutesmax.toString();
                }
                else {
                    runtimep1minutesmaxFormat = runtimep1minutesmax;
                }
        
                var runtimep2hours = parseInt(statArray[2]);
                var runtimep2minutesArray = (statArray[2] + "").split(".");
                var runtimep2minutes = runtimep2minutesArray[1] / 10;
                runtimep2minutes = 60 * runtimep2minutes;
                var runtimep2minutesFormat;
                if (runtimep2minutes.toString().length === 1) {
                    runtimep2minutesFormat = '0' + runtimep2minutes.toString();
                }
                else {
                    runtimep2minutesFormat = runtimep2minutes;
                }
        
                var runtimep2hoursmax = parseInt(statArray[3]);
                var runtimep2minutesArraymax = (statArray[3] + "").split(".");
                var runtimep2minutesmax = runtimep2minutesArraymax[1] / 10;
                runtimep2minutesmax = 60 * runtimep2minutesmax;
                var runtimep2minutesmaxFormat;
                if (runtimep2minutesmax.toString().length === 1) {
                    runtimep2minutesmaxFormat = '0' + runtimep2minutesmax.toString();
                }
                else {
                    runtimep2minutesmaxFormat = runtimep2minutesmax;
                }        

                var runtimeNSP1hours = parseInt(statArray[4]);
                var runtimeNSP1minutesArray = (statArray[4] + "").split(".");
                var runtimeNSP1minutes = runtimeNSP1minutesArray[1] / 10;
                runtimeNSP1minutes = 60 * runtimeNSP1minutes;
                var runtimeNSP1minutesFormat;
                if (runtimeNSP1minutes.toString().length === 1) {
                    runtimeNSP1minutesFormat = '0' + runtimeNSP1minutes.toString();
                }
                else {
                    runtimeNSP1minutesFormat = runtimeNSP1minutes;
                }                  
        
                var runtimeNSP2hours = parseInt(statArray[5]);
                var runtimeNSP2minutesArray = (statArray[5] + "").split(".");
                var runtimeNSP2minutes = runtimeNSP2minutesArray[1] / 10;
                runtimeNSP2minutes = 60 * runtimeNSP2minutes;
                var runtimeNSP2minutesFormat;
                if (runtimeNSP2minutes.toString().length === 1) {
                    runtimeNSP2minutesFormat = '0' + runtimeNSP2minutes.toString();
                }
                else {
                    runtimeNSP2minutesFormat = runtimeNSP2minutes;
                }                  
                
                    setRunetimeP1(runtimep1hours + "h " + runtimep1minutesFormat + "min");   
                    setRunetimeP2(runtimep2hours + "h " + runtimep2minutesFormat + "min");  
                    setRunetimeP1max(runtimep1hoursmax + "h " + runtimep1minutesmaxFormat + "min");  
                    setRunetimeP2max(runtimep2hoursmax + "h " + runtimep2minutesmaxFormat + "min");  
                    setRunetimeNSP1(runtimeNSP1hours + "h " + runtimeNSP1minutesFormat + "min");  
                    setRunetimeNSP2(runtimeNSP2hours + "h " + runtimeNSP2minutesFormat + "min");  
            }
        })

        client.on('close', function () {            
            console.log("Trennstation DISCONNECTED")        
        })

        // const voltSubmit1 = document.getElementById('voltSubmit1');
        // voltSubmit1.addEventListener('click', (event) => {
        //     if (event.target.click) {                
        //         setSollCH1();
        //        }
        // });

        // HELPER
        function barray2Value(array) {
            var result = 0;
            var d = 1;
        
            for (var i = 0; i < array.length; i++) {
                result += array[i] * (d);
                d = d * 256;
            }
            return result;
        }
    
        return () => client.end(); // <-- kill on unmount
    }, []) // <-- empty dependency array

    return (
        <div className="module-wrapper">
            <div className="distancer">
                {/* // SUBNAVIGATION */}
                <div className="subnav">
                    <div className="fltl">
                        <Link to="/Devices">
                            <Button variant="contained" color="primary" className="back-button" startIcon={<ArrowBackIosTwoToneIcon/>}>Geräte</Button>
                        </Link>
                    </div>
                    <div className="fltr">
                        <Button id="trennstationOnline" variant="outlined" color="primary" className="online fltr hide" disableElevation disabled startIcon={<LinkIcon/>}>Verbunden</Button>
                        <Button id="trennstationOffline" variant="outlined" color="primary" className="offline fltr" disableElevation disabled startIcon={<LinkOffIcon/>}>Verbindung</Button>
                    </div>
                    <div className="spacer"></div>                     
                </div>
                {/* END SUBNAVIGATION*/}  
                <div className="outer-title">
                    Trennstation EZG Showroom
                </div>
                <Row>
                    <Col sm="12" md="7" lg="6" xl="6">
                        <Card className="deviceCard">
                            <Card.Header>
                                <div className="card-title">Seriennummer: FT0104</div>                              
                            </Card.Header>
                            <Card.Body style={{padding: 0, margin: 0, marginLeft: 15}}>
                            <div id="divSchema" className="divSchema">
                                {/* <!-- Hintergrundbild --> */}
                                <img id="imgSchema" src={DTbackgroundImage} style={{height: 300, width: 345, position: "absolute", left: 29}}/>                  
                                <div id="disabled_overlay_pump1" className="pumpOverlay" style={{display: "none"}}></div>
                                <div id="disabled_overlay_pump2" className="pumpOverlay pumpOuter2" style={{display: "none"}}></div>

                                {/* <!-- Erste Pumpe --> */}
                                <div id="pump1" className="pumpOuter" >
                                    <div id="pump1On" className="pumpOn hide">
                                        <div className="pumpOnIcon"></div>
                                    </div>
                                    <div id="pump1Off" className="pumpOff">
                                        <div className="pumpOffIcon"></div>
                                    </div>
                                    <div id="pump1FreqIco" className='hide'>
                                        <span id="pump1FreqLabel">{p1Leistung}</span>%
                                    </div>
                                </div>

                                {/* <%--Zweite Pumpe--%> */}
                                <div id="pump2" className="pumpOuter pumpOuter2" >
                                    <div id="pump2On" className="pumpOn hide">
                                        <div className="pumpOnIcon"></div>
                                    </div>
                                    <div id="pump2Off" className="pumpOff">
                                        <div className="pumpOffIcon"></div>
                                    </div>
                                    <div id="pump2FreqIco" className='hide'>
                                        <span id="pump2FreqLabel">{p2Leistung}</span>%
                                    </div>
                                </div>

                                {/* <%--Regenwasserpumpe--%> */}
                                <div id="pump3" className="pumpOuter pumpOuterRw"  style={{left:375, top: 324, display: "none"}}>
                                    <div id="pump3On" className="pumpOn" style={{display: "none"}}>
                                        <div className="pumpOnIcon up"></div>
                                    </div>
                                    <div id="pump3Off" className="pumpOff" style={{display: "block"}} >
                                        <div className="pumpOffIcon up"></div>
                                    </div>
                                </div>

                                {/* <%--Betriebswassertank--%> */}
                                <div id="tank" className="BigTank" >
                                    <div className="fillingLevel hint" title="Füllstand">
                                        <span className="fillingLevelValue outputLabel">{fillingLevel}%</span>
                                    </div>
                                    <div id="water" className="waterBigTank" style={waterInlineStyle}></div>
                                    <div className="BigTankFeet"></div> 
                                </div>

                                {/* <%--Regenwassertank--%> */}
                                <div id="rwTankComponents" style={{display:"none"}}>
                                    <div id="cisternTop" className="cisternTop"></div>
                                    <div id="rwTank" className="BigTank" >
                                        <div className="fillingLevel fillingLevelRw hint" title="Füllstand " style={{top: 35, left: 35}}>
                                            <span className="fillingLevelRwValue outputLabel"></span>
                                        </div>
                                        <div id="waterRw" className="waterBigTank"></div>
                                        <div className="BigTankFeet"></div>
                                    </div>
                                </div>

                                {/* <%--Regenwassertank Ablauf--%> */}
                                <div id="rwPipe" className="rwPipe" style={{display: "none"}}>	
                                    <div id="pipeEnd" className="pipeEnd"></div>
                                    <div id="rwLine" className="rwLine"></div>
                                    <div id="rwLine2" className="rwLine2"></div>
                                    <div id="rwLine3" className="rwLine3"></div>
                                    <div id="rwLine4" className="rwLine4"></div>
                                </div>

                                {/* <%--Regenwasserstopp--%> */}
                                <div id="rwStopp" style={{display: "none"}}>
                                    <div id="rwStoppVent" className="nspOuter" style={{left: 350, top: 124 }}>
                                        <div id="rwStoppZu" className="nspZu" style={{display: "block"}}></div>
                                        <div id="rwStoppAuf" className="nspAuf"></div>
                                    </div>
                                    <div id="rwStoppLabel" className="valveStatus hint" title="rwStoppLabel" style={{top: 105, left: 374}}>
                                        Regenwasserstopp
                                    </div>
                                </div>

                                {/* <%--Erster Zusatzzulauf NSP2 !!--%> */}
                                <div id="AdditionalNSP" style={{display: "none"}}>
                                    <div id="nsp2" className="nspOuter" style={{left: 94, top: 81}}>
                                        <div id="nspZu2" className="nspZu" style={{display: "block"}}></div>
                                        <div id="nspAuf2" className="nspAuf"></div>
                                    </div>
                                    <div id="lineNsp2" style={{width: 100, height: 3, backgroundColor: "black", top: 96, left: 80, position: "absolute"}}></div>
                                    <div id="endNsp2" className="pipeEnd flipHorizontal" style={{top: 88, left: 180}}></div>
                                    <div id="divNSP2" className="valveStatus hint" title="NSP2" style={{top:60, left: 92}}>
                                        NSP 2	
                                    </div>
                                </div>

                                {/* <%--Zweiter Zusatzzulauf--%> */}
                                <div id="nsp3" className="nspOuter"  style={{left: 366, top: 224, display: "none"}}>
                                    <div id="nspZu3" className="nspZu"  style={{display: "block"}}></div>
                                    <div id="nspAuf3" className="nspAuf" ></div>
                                </div>
                                <div id="lineNsp3" style={{width:80 , height:3, backgroundColor: "black", top:244, left:298, position: "absolute",display: "none"}}></div>
                                                        
                                {/* <%--Standard Zulauf--%> */}
                                <div id="nsp" className="nspOuter">
                                    <div id="nspZu" className="nspZu"></div>
                                    <div id="nspAuf" className="nspAuf hide"></div>
                                </div>
                                <div id="lineNsp" style={{width: 100, height: 3, backgroundColor: "black", position: "absolute", top: 80, left: 225}}></div>
                                <div id="nspPipeEnd" className="pipeEnd" style={{top: 72, left: 210}}></div>

                                {/* <!-- Autodrain --> */}
                                <div id="autodrain" className="nspOuter hide" style={{top:176,left:39}}>
                                    <div id="autodrainAuf" className="nspAuf hide" style={{transform: "rotate(90deg)"}}></div>
                                    <div id="autodrainZu" className="nspZu"  style={{transform: "rotate(90deg)"}}></div>
                                </div>
                                <div id="autodrainLabel" className="valveStatus hint hide" title="autodrainLabel" style={{top:137,left:25}}>
                                    Autodrain
                                </div>
                                <div id="line" className='hide' style={{width:2, height:70, backgroundColor:"black", top:168, left:54, position:"absolute"}}></div>

                                {/* <!-- Beschriftung Durchfluss --> */}
                                <div id="Bauteil5" className="Bauteil5" style={{display: "none"}}>
                                    <div id="valve5" className="valveStatus hint">
                                        Durchfluss<br />
                                        <span className="outputLabel">-</span> l/h
                                    </div>
                                    <div id="DurchflussLine" className="grayLine" style={{height:20,top:59,left:294}}></div>
                                </div>

                                {/* <!-- Beschriftung Durchfluss 2 --> */}
                                <div id="Bauteil6" className="Bauteil6" style={{display: "none"}}>
                                    <div id="valve6" className="valveStatus hint" style={{top: 22, left: 79}}>
                                        Durchfluss<br />
                                        <span className="outputLabel">-</span> l/h
                                    </div>
                                    <div id="DurchflussLine2" className="grayLine" style={{height:20,top:59,left:110}}></div>
                                </div>

                                {/* <!-- Beschriftung NSP --> */}
                                <div id="Bauteil0" className="Bauteil0">
                                    <div id="valve0" className="valveStatus hint" style={{top:44,left:260}}>
                                        NSP 1<br />                                        
                                    </div>
                                </div>

                                {/* <!-- Beschriftung NSP --> */}
                                <div id="Bauteil1" className="Bauteil1">
                                    <div id="valve1" className="valveStatus hint" style={{top:340}}>
                                        Ausgangsdruck:<br />
                                        <span className="outputLabel">{druckausgang}</span> bar
                                    </div>
                                    <div id="AusgangsdruckLine" className="grayLine" style={{height:80,width:2,top:261, left: 45}}></div>
                                </div>

                                {/* <!-- Beschriftung Temperatur -->  */}
                                <div id="Bauteil2" className="Bauteil2" style={{display: "none"}}>
                                    <div id="valve2" className="valveStatus hint">
                                        Temperatur:<br />
                                        <span className=" outputLabel">-</span>°C 
                                    </div>
                                    <div id="TemperaturLine" className="grayLine" style={{height:38, top:118, left:294}}></div>
                                </div>

                                {/* <!-- Beschriftung Temperatur 2 -->  */}
                                <div id="Bauteil7" className="Bauteil7" style={{display: "none"}}>
                                    <div id="valve7" className="valveStatus hint" style={{top: 153, left:74}}>
                                        Temperatur:<br />
                                        <span className=" outputLabel">-</span>°C 
                                    </div>
                                    <div id="Temperatur2Line" className="grayLine" style={{height:38,top:118,left:110}}></div>
                                </div>
                            </div>
                            </Card.Body>
                        </Card>
                    </Col>    
                    <Col sm="12" md="5" lg="6" >
                        <Card className="deviceCard">
                            <Card.Header>
                                <div className="card-title">Status: {deviceStatus}</div>                              
                            </Card.Header>
                            <Card.Body style={{height: 414}}>
                                <div><span className='spanWidthSetter'>Firmware:</span><span className='spanRightSetter'>{firmware}</span></div>
                                <div><span className='spanWidthSetter'>Betriebsmodus:</span><span className='spanRightSetter'>{automode}</span></div>
                                <br></br>
                                <b>Laufzeiten</b>
                                <hr style={{padding: 0 , margin: 4}}/>
                                <div id="statsTooltipWrapper">
                                    <div id="statsTooltip">
                                        <div><span className='spanWidthSetter'>Laufzeit P1: </span><span className='spanRightSetter'>{RunetimeP1}</span></div>
                                        <div><span className='spanWidthSetter'>Max. Laufzeit P1:</span><span className='spanRightSetter'>{RunetimeP1max}</span></div>
                                        <div><span className='spanWidthSetter'>Laufzeit P2:</span><span className='spanRightSetter'>{RunetimeP2}</span></div>
                                        <div><span className='spanWidthSetter'>Max. Laufzeit P2:</span><span className='spanRightSetter'>{RunetimeP2max}</span></div>
                                        <div><span className='spanWidthSetter'>Laufzeit NSP1:</span><span className='spanRightSetter'>{RunetimeNSP1}</span></div>
                                        <div><span className='spanWidthSetter'>Laufzeit NSP2:</span><span className='spanRightSetter'>{RunetimeNSP2}</span></div>
                                    </div>
                                </div>
                                <br></br>
                                <div id="notstoppDiv" className='hide' style={{color: "#db423a", borderColor: "#db423a", border: "2px solid #db423a", width: "100%", textAlign: "center", borderRadius: 3, lineHeight: "30px"}}>{notstopp}</div>
                            </Card.Body>
                        </Card>
                    </Col>      
                </Row>
                <Row>
                    <Col>
                        <a href="https://dehoustconnect.de/Admin/ProjectManagement.aspx?p=CBnyc1Q94VzoZgIytHS3xcQfG9%2bJRX2Iley9tTFv3ZS9py0h0AseH5Ypj3gfqfBs5BKssldAf5cDM5r2KNKkoB5bZh3XpR1xchONr9Vchb5x%2bXpQ4U5b%2fMEkgg9lMookoql35sHbwS2u3hrdxh9gSfyQltIwNJ%2b4ZuZHQ1PpiXg%3d" target="blank">
                                <Button variant="contained" color="primary" className="generic-btn ecbutton" style={{marginTop: 15, float: "right"}}>zu Dehoust Connect</Button>   
                        </a>
                    </Col>
                </Row>

            </div>
        </div>
    )
}

export default Trennstation;