import React, {Component, useState, setState, Fragment, useEffect} from 'react';
import {Route, Link, Redirect} from 'react-router-dom';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TablePagination from  'react-bootstrap/Table';
import Table from 'react-bootstrap/Table';
import ArrowBackIosTwoToneIcon from '@material-ui/icons/ArrowBackIosTwoTone';
import Button from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import Icon from "../Icons";
import jwt_decode from "jwt-decode";

import eclogo from '../../assets/ec_logo.png';

import Econtrol3Group from '../econtrol3/Econtrol3Group';

// MQTT Readme
// https://www.npmjs.com/package/mqtt

function Econtrol() {

    const [userPanelGroups, setUserPanelGroups] = useState([]);

    const [panelGroupCurrentGuids, setPanelGroupCurrentGuids] = useState([]);
    const [panelGroupCurrentSollwerte, setPanelGroupCurrentSollwerte] = useState([]);
    const [panelGroupCurrentModes, setPanelGroupCurrentModes] = useState([]);

    const [currentSollwert, setCurrentSollwert] = useState(['']);
    const [currentMode, setCurrentMode] = useState(['']);

    const [currentEinstrahlung, setCurrentEinstrahlung] = useState(['']);
    const [currentModeString, setCurrentModeString] = useState(['']);  
        
    useEffect(()=>{

        var token = JSON.parse(sessionStorage.getItem('token'));
        var userID;

        if (token === null) {
            sessionStorage.removeItem('token');
            localStorage.removeItem('token');   
            <Redirect from="*" to="/" />
            window.location.reload();
        }
        else {
            var decodedJwt = jwt_decode(token.token);
            userID = decodedJwt.user_id;
            console.log(decodedJwt)
        }

        if (userID !== null) {

            const requestOptionsECHistory = {
                method: 'GET',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}                        
            }

            // FETCH 
            fetch('https://api.zvconnect.de/project/getprojectpanels/?projectID=73&userID=' + userID, requestOptionsECHistory)
            .then(response => response.json())
            .then(responseJson => {parseUserPanelGroups(responseJson);
            })            
        }

        function parseUserPanelGroups(responseJson) { 
            
            var sortedPanelGroups = responseJson.sort((a,b) => a.panelID.id - b.panelID.id)

            setUserPanelGroups(responseJson, responseJson);

            responseJson.forEach((element)=> {

            var panelGuid = element.panelID.panelGUID     

            // let currentGuidArray = [panelGroupCurrentGuids];
            // currentGuidArray.push({panelGuid})            

            client.subscribe(MasterTopic + panelGuid + "/status", 1);
            console.log(MasterTopic + panelGuid + "/status")
            setUserPanelGroupEventListeners(element.panelID.panelGUID);
           })      

            // client.subscribe(MasterTopic+"OUT/#", 1);
			// client.subscribe(MasterTopic+"command",1);
            // client.subscribe(MasterTopic+"status",1);
        }

        function setUserPanelGroupEventListeners(panelGuid) {

            for (let i=0; i <= 10; i++) {
                var button = document.getElementById(panelGuid+ '_sollwert' + i*10);
                button.addEventListener('click', (event) => {
                    var val = i*10;
                    var data = {"sollwert":val, "group":panelGuid}; 
                    client.publish(MasterTopic + "command", JSON.stringify(data), {retain: false}); 
                    console.log("Publishing " + val + " to: "+ MasterTopic + "command")
                });   
            }

            // Auto Mode 1
            var btnMA = document.getElementById(panelGuid+ '_ModeAuto');
            btnMA.addEventListener('click', (event) => {
                var val = 1;
                var data = {"modus":val, "group":panelGuid}; 
                client.publish(MasterTopic + "command", JSON.stringify(data), {retain: false}); 
                console.log("Publishing Mode " + val + " to: "+ MasterTopic + "command")
            });  

            // Manual Mode 2
            var btnMM = document.getElementById(panelGuid+ '_ModeManual');
            btnMM.addEventListener('click', (event) => {
                var val = 2;
                var data = {"modus":val, "group":panelGuid}; 
                client.publish(MasterTopic + "command", JSON.stringify(data), {retain: false}); 
                console.log("Publishing Mode " + val + " to: "+ MasterTopic + "command")
            });  
        }

        function processSollwert(groupGuid, groupSollwert) {
            console.log("Sollwert " + groupSollwert + " for: " + groupGuid);

            var cSWSpan = document.getElementById(groupGuid + '_currentSollwert');
            if (cSWSpan !== null && cSWSpan !== undefined) {
                cSWSpan.innerHTML = groupSollwert;

                for (let i=0; i <= 10; i++) {
                    var swBtn = document.getElementById(groupGuid + '_sollwert' + i*10);
                    if (swBtn !== null && swBtn !== undefined) {
                        swBtn.classList.remove('green');
                    }
                }

                var swBtn = document.getElementById(groupGuid + "_sollwert" + groupSollwert);    
                console.log(groupGuid + "_sollwert" + groupSollwert)            
                if (swBtn !== null && swBtn !== undefined) {
                    swBtn.classList.add('green');
                }
            }
        }

        function processModus(groupGuid, groupMode) {
            console.log("Mode " + groupMode + " for: " + groupGuid);

            // Auto Mode 1
            // Manual Mode 2

            if (groupMode === 1) {
                var MMBtn = document.getElementById(groupGuid + '_ModeManual');
                var AMBtn = document.getElementById(groupGuid + '_ModeAuto');
                if (MMBtn !== null && MMBtn !== undefined) {
                    MMBtn.classList.remove('green');
                    AMBtn.classList.add('green');
                }                
            }

            if (groupMode === 2) {
                var MMBtn = document.getElementById(groupGuid + '_ModeManual');
                var AMBtn = document.getElementById(groupGuid + '_ModeAuto');
                if (MMBtn !== null && MMBtn !== undefined) {
                    MMBtn.classList.add('green');
                    AMBtn.classList.remove('green');
                }                
            }            
        }

        const mqtt = require('mqtt')
        const Broker = "mqtts://mqtt.consoft.de:8001";
        const options = {
            username : "testEControlTempClient",
            password : "SchneckenTempo22",
            protocol: 'mqtts',
            clientId: '1_Test.EControl.Temp.Ecki_' + Math.floor(Math.random() * 1000)
        };

        const MasterTopic = "Test/EControl/338e3d1b-e36d-419d-8c43-054b41da0a75/";
        
        const client = mqtt.connect(Broker, options);
            
        client.on('connect', function () {    
         
            //TODO     

            console.log('ECONTROL2 CONNECTED');                      
        
            client.subscribe(MasterTopic, function (err) {;
                if (!err) {
                console.log('Subscribed to MasterTopic');                                       
                }
            })
        })

        var voltOfflineIndicator = document.getElementById("ECGOffline");
        var voltOnlineIndicator = document.getElementById("ECGOnline");       

        client.on('message', function (topic, message) {

            console.log("Message: " + message);

            if (topic.toString().endsWith('status')) {
                
                if (voltOnlineIndicator !=  null) {
                    voltOfflineIndicator.classList.add('hide');   
                    voltOnlineIndicator.classList.remove('hide'); 
                }   

                if (message.length !== 0) {
                    var commandParsed = JSON.parse(message);

                    if (commandParsed.hasOwnProperty('sollwert')) {
                       var topicSplit = topic.split("/");                      
                       processSollwert(topicSplit[3], commandParsed.sollwert)
                    }
                    
                    if (commandParsed.hasOwnProperty('modus')) {
                        var topicSplit = topic.split("/");
                        processModus(topicSplit[3], commandParsed.modus)
                    }
                }
            }        
        })

        client.on('close', function () {            
            console.log("EControl2 DISCONNECTED")        
        })

        // // Automodus
        // const automodeButton = document.getElementById('btnAutomode');
        // automodeButton.addEventListener('click', (event) => {
        //     var val = 1;
        //     var user = sessionStorage.getItem('username');
        //     var data = {"modus":val, "username":user};
        //     client.publish(MasterTopic+"command", JSON.stringify(data), {retain: false});           
        //     console.log(JSON.stringify(data))    
        // });     
        
        // // Sitzungsmodus
        // const sitzungsmodeButton = document.getElementById('btnSitzungmode');
        // sitzungsmodeButton.addEventListener('click', (event) => {
        //     var val = 2;
        //     var user = sessionStorage.getItem('username');
        //     var data = {"modus":val, "username":user};
        //     client.publish(MasterTopic+"command", JSON.stringify(data), {retain: false});           
        //     console.log(JSON.stringify(data))    
        // }); 

        return () => client.end(); // <-- kill on unmount
    }, []) // <-- empty dependency array

    return (
    <div className="module-wrapper">
            <div className="distancer"></div>
            {/* // SUBNAVIGATION */}
            <div className="subnav">
                <div className="fltl">
                    <Link to="/">
                        <Button variant="contained" color="primary" className="back-button"  startIcon={<ArrowBackIosTwoToneIcon/>}>Projekte</Button>
                    </Link>
                </div>
                <div className="fltr">   
                    <Button id="ECGOnline" variant="outlined" color="primary" className="online fltr hide" disableElevation disabled startIcon={<LinkIcon/>}>Verbunden</Button>
                    <Button id="ECGOffline" variant="outlined" color="primary" className="offline fltr" disableElevation disabled startIcon={<LinkOffIcon/>}>Verbindung</Button>
                </div>
                <div className="spacer"></div>                     
            </div>                
            {/* END SUBNAVIGATION*/}  
            <div className="outer-title kuppelControlHeader">
                <img className="" src={eclogo} alt="Logo" style={{height: "50px", margin: "0px 0px 5px 0px"}}/> Projekt Bartels
            </div>
            <div style={{clear: 'both'}}></div>
            {userPanelGroups.map(dataSet =>  (
                <Econtrol3Group panelGroupName={dataSet.panelID.name} panelGroupDesc={dataSet.panelID.description} panelGroupGuid={dataSet.panelID.panelGUID} panelGroupSollwerte={panelGroupCurrentSollwerte} panelGroupModes={panelGroupCurrentModes}/>
            ))}
        </div>
    );
}

export default Econtrol;